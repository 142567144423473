import React, { Component } from 'react';
import Flag from 'react-world-flags';

export class Test extends Component {

  render() {
      return (
          <div className="home">
             <div className="row">
                {/* <h1>squarefight</h1> */}
              </div>
              <div className="row">
                <section className="col-sm-6">
                    <h3>Test Flags</h3>

                    <Flag code={"AF"} />Afghanistan
                        <Flag code={"AL"} />Albania
                        <Flag code={"DZ"} />Algeria
                        <Flag code={"AS"} />American Samoa
                        <Flag code={"AD"} />Andorra
                        <Flag code={"AO"} />Angola
                        <Flag code={"AG"} />Antigua and Barbuda
                        <Flag code={"AR"} />Argentina
                        <Flag code={"AM"} />Armenia
                        <Flag code={"AU"} />Australia
                        <Flag code={"AT"} />Austria
                        <Flag code={"AZ"} />Azerbaijan
                        <Flag code={"BS"} />Bahamas
                        <Flag code={"BH"} />Bahrain
                        <Flag code={"BD"} />Bangladesh
                        <Flag code={"BB"} />Barbados
                        <Flag code={"BY"} />Belarus
                        <Flag code={"BE"} />Belgium
                        <Flag code={"BZ"} />Belize
                        <Flag code={"BJ"} />Benin
                        <Flag code={"BM"} />Bermuda
                        <Flag code={"BT"} />Bhutan
                        <Flag code={"BO"} />Bolivia
                        <Flag code={"BA"} />Bosnia and Herzegovina
                        <Flag code={"BW"} />Botswana
                        <Flag code={"BR"} />Brazil
                        <Flag code={"BN"} />Brunei
                        <Flag code={"BG"} />Bulgaria
                        <Flag code={"BF"} />Burkina Faso
                        <Flag code={"BU"} />Burma
                        <Flag code={"BI"} />Burundi
                        <Flag code={"KH"} />Cambodia
                        <Flag code={"CM"} />Cameroon
                        <Flag code={"CA"} />Canada
                        <Flag code={"CV"} />Cape Verde
                        <Flag code={"CF"} />Central African Republic
                        <Flag code={"TD"} />Chad
                        <Flag code={"CL"} />Chile
                        <Flag code={"CN"} />China
                        <Flag code={"CO"} />Colombia
                        <Flag code={"KM"} />Comoros
                        <Flag code={"CD"} />Congo
                        <Flag code={"CR"} />Costa Rica
                        <Flag code={"CI"} />Côte d'Ivoire (Ivory Coast)
                        <Flag code={"HR"} />Croatia
                        <Flag code={"CU"} />Cuba
                        <Flag code={"CY"} />Cyprus
                        <Flag code={"CZ"} />Czech Republic
                        <Flag code={"DK"} />Denmark
                        <Flag code={"DJ"} />Djibouti
                        <Flag code={"DM"} />Dominica
                        <Flag code={"DO"} />Dominican Republic
                        <Flag code={"EC"} />Ecuador
                        <Flag code={"EG"} />Egypt
                        <Flag code={"SV"} />El Salvador
                        <Flag code={"EK"} />Equatorial Guinea
                        <Flag code={"ER"} />Eritrea
                        <Flag code={"EE"} />Estonia
                        <Flag code={"ET"} />Ethiopia
                        <Flag code={"FJ"} />Fiji
                        <Flag code={"FI"} />Finland
                        <Flag code={"FR"} />France
                        <Flag code={"GA"} />Gabon
                        <Flag code={"GM"} />Gambia
                        <Flag code={"GE"} />Georgia
                        <Flag code={"DE"} />Germany
                        <Flag code={"GH"} />Ghana
                        <Flag code={"GR"} />Greece
                        <Flag code={"GD"} />Grenada
                        <Flag code={"GT"} />Guatemala
                        <Flag code={"GN"} />Guinea
                        <Flag code={"GW"} />Guinea-Bissau
                        <Flag code={"GY"} />Guyana
                        <Flag code={"HT"} />Haiti
                        <Flag code={"VA"} />Holy See
                        <Flag code={"HN"} />Honduras
                        <Flag code={"HU"} />Hungary
                        <Flag code={"IS"} />Iceland
                        <Flag code={"IN"} />India
                        <Flag code={"ID"} />Indonesia
                        <Flag code={"IR"} />Iran
                        <Flag code={"IQ"} />Iraq
                        <Flag code={"IE"} />Ireland
                        <Flag code={"IL"} />Israel
                        <Flag code={"IT"} />Italy
                        <Flag code={"JM"} />Jamaica
                        <Flag code={"JP"} />Japan
                        <Flag code={"JO"} />Jordan
                        <Flag code={"KZ"} />Kazakhstan
                        <Flag code={"KE"} />Kenya
                        <Flag code={"KI"} />Kiribati
                        <Flag code={"KW"} />Kuwait
                        <Flag code={"KG"} />Kyrgyzstan
                        <Flag code={"LA"} />Laos
                        <Flag code={"LV"} />Latvia
                        <Flag code={"LB"} />Lebanon
                        <Flag code={"LS"} />Lesotho
                        <Flag code={"LR"} />Liberia
                        <Flag code={"LY"} />Libya
                        <Flag code={"LI"} />Liechtenstein
                        <Flag code={"LT"} />Lithuania
                        <Flag code={"LU"} />Luxembourg
                        <Flag code={"MK"} />Macedonia
                        <Flag code={"MG"} />Madagascar
                        <Flag code={"MW"} />Malawi
                        <Flag code={"MY"} />Malaysia
                        <Flag code={"MV"} />Maldives
                        <Flag code={"ML"} />Mali
                        <Flag code={"MT"} />Malta
                        <Flag code={"MH"} />Marshall Islands
                        <Flag code={"MR"} />Mauritania
                        <Flag code={"MU"} />Mauritius
                        <Flag code={"MX"} />Mexico
                        <Flag code={"FM"} />Micronesia Federated States
                        <Flag code={"MD"} />Moldova
                        <Flag code={"MC"} />Monaco
                        <Flag code={"MN"} />Mongolia
                        <Flag code={"MA"} />Morocco
                        <Flag code={"MZ"} />Mozambique
                        <Flag code={"NA"} />Namibia
                        <Flag code={"NR"} />Nauru
                        <Flag code={"NP"} />Nepal
                        <Flag code={"NL"} />Netherlands
                        <Flag code={"NZ"} />New Zealand
                        <Flag code={"NI"} />Nicaragua
                        <Flag code={"NE"} />Niger
                        <Flag code={"NG"} />Nigeria
                        <Flag code={"KP"} />North Korea
                        <Flag code={"NO"} />Norway
                        <Flag code={"OM"} />Oman
                        <Flag code={"PK"} />Pakistan
                        <Flag code={"PW"} />Palau
                        <Flag code={"PA"} />Panama
                        <Flag code={"PG"} />Papua New Guinea
                        <Flag code={"PY"} />Paraguay
                        <Flag code={"PE"} />Peru
                        <Flag code={"PH"} />Philippines
                        <Flag code={"PL"} />Poland
                        <Flag code={"PT"} />Portugal
                        <Flag code={"QA"} />Qatar
                        <Flag code={"RO"} />Romania
                        <Flag code={"RU"} />Russia
                        <Flag code={"RW"} />Rwanda
                        <Flag code={"KN"} />Saint Kitts and Nevis
                        <Flag code={"LC"} />Saint Lucia
                        <Flag code={"VC"} />Saint Vincent and the Grenadines
                        <Flag code={"WS"} />Samoa
                        <Flag code={"SM"} />San Marino
                        <Flag code={"ST"} />Sao Tome and Principe
                        <Flag code={"SA"} />Saudi Arabia
                        <Flag code={"SN"} />Senegal
                        <Flag code={"SC"} />Seychelles
                        <Flag code={"SL"} />Sierra Leone
                        <Flag code={"SG"} />Singapore
                        <Flag code={"SK"} />Slovakia
                        <Flag code={"SI"} />Slovenia
                        <Flag code={"SB"} />Solomon Islands
                        <Flag code={"SO"} />Somalia
                        <Flag code={"ZA"} />South Africa
                        <Flag code={"KR"} />South Korea
                        <Flag code={"ES"} />Spain
                        <Flag code={"LK"} />Sri Lanka
                        <Flag code={"SD"} />Sudan
                        <Flag code={"SR"} />Suriname
                        <Flag code={"SZ"} />Swaziland
                        <Flag code={"SE"} />Sweden
                        <Flag code={"CH"} />Switzerland
                        <Flag code={"SY"} />Syria
                        <Flag code={"TW"} />Taiwan
                        <Flag code={"TJ"} />Tajikistan
                        <Flag code={"TZ"} />Tanzania
                        <Flag code={"TH"} />Thailand
                        <Flag code={"TG"} />Togo
                        <Flag code={"TO"} />Tonga
                        <Flag code={"TT"} />Trinidad and Tobago
                        <Flag code={"TN"} />Tunisia
                        <Flag code={"TR"} />Turkey
                        <Flag code={"TX"} />Turkmenistan
                        <Flag code={"TV"} />Tuvalu
                        <Flag code={"UG"} />Uganda
                        <Flag code={"UA"} />Ukraine
                        <Flag code={"AE"} />United Arab Emirates
                        <Flag code={"UK"} />United Kingdom
                        <Flag code={"US"} />United States
                        <Flag code={"UY"} />Uruguay
                        <Flag code={"UZ"} />Uzbekistan
                        <Flag code={"VU"} />Vanuatu
                        <Flag code={"VE"} />Venezuela
                        <Flag code={"VN"} />Vietnam
                        <Flag code={"YE"} />Yemen
                        <Flag code={"YU"} />Yugoslavia
                        <Flag code={"ZM"} />Zambia
                        <Flag code={"ZW"} />Zimbabwe

                </section>
                <section className="col-sm-6">
                  
                </section>
              </div>
              <div className="row">
              

              </div>
          </div>
    );
  }
}
