import React, { Component } from 'react';

export const Opponent = (props) => {
    const gamer = props.gamer;
    const game = props.game;


    return (
        <>
        </>
    );

        
    // }

}

export default Opponent;