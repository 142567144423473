import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import { useUserCtx, useUserCtxState, UserCtxConsumer } from './User';

export const NewGame = (props) => {

    const [playerName, setPlayerName] = React.useState();
    const [homeCountryCode, setHomeCountryCode] = React.useState();
    
    const newGameUserUpdate = (user) => {
        setPlayerName(user.playerName);
        setHomeCountryCode(user.homeCountryCode);
    }
    const[user, setUser] = useUserCtx();
    user.addNotifier('newgame', newGameUserUpdate);

    const navigate = useNavigate();

    const newGame = (e) => {
        // debugger;
        // if (e.target.disabled) return false;
        console.log('New game requested');
        
        (async () => {
          //debugger;
          var data = {
              // playerName: name,
              // homeCountryCode: homeCountryCode,
              colour: 'none' // colour
          };
          var response = await fetch('/api/gamedata/new', {
              method: 'POST',
              credentials: "same-origin",
              headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain, */*' },
              body: JSON.stringify(data)
          });
    
          //debugger;
          const content = await response.json();
    
          if (content != null) {
              if (response.status === 200) {
                  console.log(content.principalGameUrl);
    
                  var parser = document.createElement('a');
                  parser.href = content.principalGameUrl;
                  console.log(parser.pathname);
                  var path = parser.pathname;
                  if (!path.startsWith('/') && !path.startsWith('http')) path = '/' + path;
                  // this.props.history.push(path);
                //   window.location = path; // TODO: do this proper
                navigate(path);

              } else {
                  //var keys = Object.Keys(content);
    
                  console.log(content);
              }
              //debugger;
            }
        })();
    
        return false;
      }
    

    newGame();

    // var profileString = localStorage.getItem("profile");
    // var profile = JSON.parse(profileString);
    if (playerName)
    {
        return ( 
            <h1>
                Loading
            </h1>
        ); 
    }

    return 
    (
        <h4>
            You need to setup a small profile before you can start a game<br />
            &dArr;&dArr;&dArr;<br />
            <Profile open={true} />
        </h4>
    );
}

export default NewGame;