import React, { Component } from 'react'
import { Piece } from './Piece'

export const Vote = (props) => {
    const gameHandle = props.gameHandle;
    const game = gameHandle.game;
    const vote = props.vote;

    if (!vote || vote.gamePieceId == null) {
        return (<li>
            Waiting..
        </li>);
    }

    var piece = game.pieces[vote.gamePieceId];
    return (
        <li className="vote">
            <Piece gameHandle={gameHandle} series={piece.series} colour={piece.colour} type={piece.type} />
            <span>to {vote.toTile}</span>
        </li>
    );
}

export default Vote;