import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Profile from './Profile';

export const JoinGame = (props) => {
    const gameHandle = props.gameHandle;
    const game = gameHandle.game;
    const me = game.me;
    const gameId = props.gameId || game.id;
    const gamerId = props.gamerId || game.principal.gamerId;
    const canJoin = me.canJoin;

    const handleJoin = () => {
        if (gameHandle)
        {
            gameHandle.setPending(true);
            
            gameHandle.gameHub.joinGame(gameId, gamerId);
        } 
        else
        {
            console.log("not plumbed in yet");
        }
    };

    if (canJoin) {
        return (
            <div>
                {/* <div className="subHeading">Waiting for a player</div> */}
                <Button variant="contained" onClick={handleJoin} color="success">
                    Join Game
                </Button>
            </div>
        );
    }

    return (
        <></>
    );
}

export default JoinGame;