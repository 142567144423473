import React, { Component } from 'react'
import { Piece } from './Piece'

export const Move = (props) => {
    var gameHandle = props.gameHandle;
    // var moveId = props.moveId;
    // var move = gameHandle.game.moves[moveId];
    var pieceId = props.pieceRef;
    var piece = gameHandle.game.pieces[pieceId];
    var tile = props.tile;

    return (
        <li className="move">
            <Piece gameHandle={gameHandle} series={piece.series} colour={piece.colour} type={piece.type} tile={tile} />
            <span>to {tile}</span>
        </li>
    );
}

export default Move;