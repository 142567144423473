import React, { Component } from 'react';
import {newGame} from './Game';
import { useUserCtx, useUserCtxState, UserCtxConsumer } from './User';
import Profile from './Profile'
import { Link } from 'react-router-dom';


export const Home = (props) => {
  // displayName = Home.name
  const homeUserUpdate = (user) => {
    setPlayerName(user.playerName);
    setHomeCountryCode(user.homeCountryCode);
  }
  const[user, setUser] = useUserCtx();
  const[playerName, setPlayerName] = React.useState(user.playerName);
  const[homeCountryCode, setHomeCountryCode] = React.useState(user.homeCountryCode);
  user.addNotifier('home', homeUserUpdate);

    return (
      <div className="home">
          <div className="row">
            {/* <h1>squarefight</h1> */}
          </div>
          {playerName ? 
            <div className="row">
            <section className="col-sm-6">
                <p>Go kick ass</p>
                {/* <a href="/new">New game</a> */}
                {/* <a onClick={newGame}>New game</a> */}
                <Link to='/new'>New Game</Link>

            </section>
          </div> 
          : null}
          {playerName ? 
          <div className="row">
            <section className="col-sm-6">
                <p>See your previous games</p>
                {/* <a href="/history">List games</a> */}
                <Link to='/history'>List Games</Link>
            </section>
          </div>
          : null}
          {!playerName ? 
          <div className="row">
          <section className="col-sm-6">
              <p>Setup a profile</p>
              <Profile open={true} />
          </section>
        </div>
          : null}
          <div className="row">
            <br/>
            <br/>
            This site is currently optimised for mobile<br/>
            <br/>
            <br/>
            We are still in BETA so you may experience some issues<br/>
            <br/>
            <br/>

          </div>
      </div>
  );
}
