import React, { Component } from 'react'
import './Piece.css'

export const Piece = (props) => {
    const series = props.series;
    const colour = props.colour;
    const type = props.type;
    const tile = props.tile;
    return <div tile={tile} className={[series + '_' + colour + '_' + type + ' piece'].join(' ')} />; //, selected && selected === tile ? "selected" : ""
}

export default Piece;