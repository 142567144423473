import React, { Component } from 'react'
import Piece from './Piece'

export const AddPiece = (props) => {

    const game = props.gameHandle;
    const series = props.series;
    const set = props.set;
    const colour = props.colour;

    const handleClick = (e, choice) => {
        if (game.handleAddPieceResult)
        {
            game.handleAddPieceResult(choice);
        }
    }


    return (<ul className="addPiece">
        <li onClick={(e) => handleClick(e, 'knight') }>
            <Piece gameHandle={game} series={series} set={set} colour={colour} type="knight" />
        </li>
        <li onClick={(e) => handleClick(e, 'bishop') }>
            <Piece gameHandle={game} series={series} set={set} colour={colour} type="bishop" />
        </li>
        <li onClick={(e) => handleClick(e, 'rook') }>
            <Piece gameHandle={game} series={series} set={set} colour={colour} type="rook" />
        </li>
        <li onClick={(e) => handleClick(e, 'queen') }>
            <Piece gameHandle={game} series={series} set={set} colour={colour} type="queen" />
        </li>
    </ul>);

}

export default AddPiece;