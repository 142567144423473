import React, { Component } from 'react'

export const Advert = (props) => {
    const pickAdvert = () => {
        var options = [
            "Take on the world",
            "Let them vote",
            "Test your skills",
            "Challenge your friends",
            "Yes, this space is reserved for ads"
        ];
        
        var roll = Math.floor(Math.random() * options.length);
        return options[roll];
    }

    return (<div className="advert">
        {pickAdvert()}
    </div>);
}
export default Advert;