import React, { Component } from 'react';
import Piece from './Piece';
import { useGameHubCtx } from './GameHub'


export const Tile = (props) => {
    const gameHandle = props.gameHandle;
    var game = gameHandle.game;
    var gameId = game.id;
    const me = game.me;
    const reference = props.reference;
    const pieceRef = props.pieceRef;
    const type = props.type;
    const tile = props.tile;
    const gameHub = useGameHubCtx();

    const handleClick = (e) => {
        // debugger;
        e.preventDefault();
        var tileRef = reference;
        var gameState = gameHub.gameState[gameId];
    
        // if a piece has been selected, then move that piece
        if (gameState.selectedPiece) {
            gameHandle.handleMove(gameState.selectedPiece, tileRef);
            return;
        }

        var piece = gameHandle.getPiece(tileRef);
        if (piece) {
            gameHandle.handleSelected(piece.id);
        }
    }

    const handleVote = (e) => {
        // debugger;
        e.preventDefault();
        var ref = reference;
        
        if (me.canVote)
        {
            var gameState = gameHub.gameState[gameId];
            var votedPiece = game.pieces[gameState.votedPiece];
            // var votedPiece = gameHandle.votedPiece;
            if (votedPiece) {
                gameHandle.handleVotePiece(gameState.votedPiece, ref);
                return;
            }
    
            var piece = gameHandle.getPiece(ref);
            if (piece) {
                gameHandle.handleVoting(piece.id);
            }
        }
    }

    const renderPiece = (piece) => {
        if (piece == null) return null;
        var selectedPiece = gameState.selectedPiece;

        return <Piece tile={piece.tile} pieceId={piece.id} series={piece.series} colour={piece.colour} type={piece.type} gameHandle={gameHandle} className={((selectedPiece != null && piece.id === selectedPiece.id) ? " selected" : "")} />
    }

    // /tile/null
    if (!type || type == "null") {
        return (<td className="tile null">&nbsp;</td>);
    }

    var gameState = gameHub.gameState[gameId];
    var selectedPiece = game.pieces[gameState.selectedPiece];
    var available = selectedPiece && selectedPiece.possibleMoves && selectedPiece.possibleMoves.includes(reference) || false;
    var pieceOnTile = pieceRef ? game.pieces[pieceRef] : null;

    // voting
    var voting = (gameState.votedTile && gameState.votedTile === reference);
    var candidate = tile.candidate;

    var currentVote = me.currentVote;
    var lastVote = me.lastVote;
    var voted = currentVote && lastVote  && currentVote.moveId === lastVote.moveId && pieceOnTile && lastVote.gamePieceId === pieceOnTile.id;
    // var lastVote = game.me.lastVote;
    var chosen = currentVote && lastVote && currentVote.moveId === lastVote.moveId && lastVote.toTile == reference;
    var lastVote = me.lastVote;
    var lastVotedPiece = lastVote && pieceOnTile && lastVote.gamePieceId === pieceOnTile.id && (!currentVote || lastVote.moveId != currentVote.moveId);
    var lastChosenPiece = lastVote && lastVote.toTile == reference && (!currentVote || lastVote.moveId != currentVote.moveId);
    
    var canVote = me.canVote;
    if (canVote)
    {
        return (
            <td key={reference} tile={reference} onClick={handleVote} className={["tile", type, (voting ? "voting" : ""), (candidate ? "candidate" : ""), (voted ? "voted" : ""), (chosen ? "chosen" : ""), (lastVotedPiece ? "lastVoted" : ""), (lastChosenPiece ? "lastChosen" : ""), (available ? "available" : "")].join(' ')} >
                {renderPiece(pieceOnTile)}
                <span>{reference}</span>
            </td>);
    }
    
    // moving
    var canMove = true;
    if (canMove)
    {
        var moving = pieceOnTile && gameHandle.moving && gameHandle.moving.id === pieceOnTile.id;
        var available = tile.available;
        // var selectedPiece = gameState.selectedPiece;
        var selected = pieceOnTile && selectedPiece && pieceOnTile.id === selectedPiece.id;
        return (
            <td key={reference} tile={reference} onClick={handleClick} className={["tile", type, (moving ? "moving" : (available ? "available" : "")), (selected ? "selected" : "")].join(' ')}>
                {renderPiece(pieceOnTile)}
                <span>{reference}</span>
            </td>
        );
    }
    
    return (
        <td key={reference} tile={reference} className={["tile", type].join(" ")}>
            {renderPiece(pieceOnTile)}
            <span>{reference}</span>
        </td>
    );


}

export default Tile;
