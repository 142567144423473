import React, { Component } from 'react'
import Flag from 'react-world-flags'

export const Host = (props) => {
    
    const [name, setName] = React.useState(props.name);
    const [countryCode, setCountryCode] = React.useState(props.countryCode);

    if (!countryCode && !name)
    {
        return (<div className='host'>
                <h3>No host</h3>
            </div>);
    }

    return (<div className='host'>
                <h3>{name}</h3>
                <div className="flag"><Flag code={ countryCode } /></div>
            </div>);

}

export default Host;