import React, { createContext, useContext, useState } from 'react';
import { session } from './GameServer';

export const Games = {};
export const GameState = {};

/*
==============
Handlers
==============
*/

const GameNotifiers = {};
const handleAddGameNotifier = (id, notifier) => {
    gameInit(id);
    console.log('GameHub: adding game notifiers ' + id);
    GameNotifiers[id] = notifier;
}

const notifyGameUpdated = (game) => {
    console.log('notify gameUpdated: ' + game.id);

    // for (const [key, value] of Object.entries(GameNotifiers)) {
    //     //console.log(key, value);
    //     value(game);
    // }

    var notifyGame = GameNotifiers[game.id];
    if (notifyGame) {
        notifyGame(game);
        // gameSave(game);
        // notifyGame.forEach(function(gameNotify) {
        //     gameNotify(game);
        // });
    }
}

const handleGameUpdated = (game) => {
    Games[game.id] = game;
    notifyGameUpdated(game);
}

// var gameUrl = '/api/gamedata/fetch/{gameId}/gamer/{gamerId}';
var gameUrl = '/api/gamedata/fetch/{gameId}';

// ================
// Game State
// ================
const gameInit = (gameId) => {
    if (!Games[gameId]) Games[gameId] = {};
    if (!GameState[gameId]) GameState[gameId] = {};
}
export const gameLoad = (gameId, gamerId, callback) => {
    //var url = '/api' + props.history.location.pathname.replace('game', 'gamedata/fetch');
    var loaded;
    // var urls = props.history.location.pathname.split('/');
    // var urls = window.location.pathname.split("/");
    var url = gameUrl.replace('{gameId}', gameId).replace('{gamerId}', gamerId);
    if (gameId.length === 36) {
        // var gameId = urls[2];
        var persisted = localStorage.getItem(gameId);
        loaded = JSON.parse(persisted);
        console.log('loaded from localstorage');
    }

    if (!loaded) {
        // var url = '/api' + window.location.pathname.replace('game', 'gamedata/fetch');
        loaded = gameLoadFromCache(url);
    }

    if (loaded) {
        // gameUpdate(loaded);
        callback(loaded);
        Games[gameId] = loaded;
    }
    else
    {
        console.log('nothing loaded');
    }

    // pull anyway
    gamePull(gameId, callback);
}

const gameLoadFromCache = (url) => {
    if (false && 'serviceWorker' in navigator) {
        var CACHE_NAME = 'squarefight-cache-v1';

        // check this game is being cached
        caches.open(CACHE_NAME)
            //.then(cache => cache.addAll(game))
            .then(function (cache) {
                console.log('matching: ' + url);
                cache.match(url)
                    .then(function (response) {
                        //debugger;
                        if (response && response.ok) {
                            console.log('Loading game from cache');
                            response
                                .then(a => {
                                    console.log('text: ' + a);
                                    //if (selectedPiece) {
                                    //    addHighlightToGame(selectedPiece.possibleMoves, a);
                                    //}

                                    return a;
                                }
                                ).catch(a => {
                                    console.log('Error parsing json, deleting cache');
                                    console.log(a);
                                    // cache.delete(CACHE_NAME);
                                    // cache.put(url, null);
                                });
                        }
                    });
            });
    }
}

const gameSaveToCache = (url, response) => {
    if ('serviceWorker' in navigator) {
        var CACHE_NAME = 'squarefight-cache-v1';
        caches
            .open(CACHE_NAME)
            .then(cache => {
                cache.put(url, response);
                //return res.json();
            });
    }
}

const gameSave = (game) => {
    if (!game)
    {
        console.log('save called but game is null');
        return;
    }

    var saving = JSON.stringify(game);
    localStorage.setItem(game.id, saving);
    console.log(game.id + ': saved');

    Games[game.id] = game;

    // gameSaveToCache(game.dataUrl, game);
}

const gamePull = (gameId, callback) => {
    // console.log('pull ' + delay);
    //debugger;
    var run = async () => {
        try {
            // var url = '/api' + window.location.pathname.replace('game', 'gamedata/fetch').replace('/join', '');
            var url = '/api/gamedata/fetch/' + gameId;
            var options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*'
                },
                credentials: "same-origin",
            };

            fetch(url, options)
                .then(res => {
                    //debugger;
                    var clone = res.clone();
                    // clone.headers.append('Content-Type', 'application/json');
                    gameSaveToCache(url, res);
                    //return clone.json();
                    //gameUpdate(res);
                    return clone.json();
                })
                .then(json => {
                    gameSave(json);
                    // gameSaveToCache(url, json);
                    // gameUpdate(json);
                    // Games[gameId] = json;
                    callback(json);
                    notifyGameUpdated(json);
                });

        }
        catch (e) {
            console.log('There was an error fetching game data ' + gameId);
            console.log(e);
        }
    };

    //if (delay) {
    //    //window.setTimeout(function () { run(); if (interval) refresh(delay, interval); }, delay);
    //    window.setTimeout(function () { run(); }, delay);
    //}
    //else {
    //    run();
    //}
    run();
}


var presenece = 'disconnected';
const setPresence = (pres) => {
    presenece = pres;
}

const online = (gameId, gamerId) => {
    if (!gameId) {
        console.log('no game ready');
        return;
    }

    console.log("online");
    sesh.online(gameId, gamerId);
    // gameHub.online(gameId, gamerId);
    // hubConnection.invoke("online", gameId, gamerId);
    setPresence('online');
}

const away = (gameId, gamerId) => {
    console.log('away');
    // hubConnection.invoke("away", gameId, gamerId);
    sesh.away(gameId, gamerId);
    setPresence('away');
    // setConnection(1);
}

const disconnected = () => {
    console.log('disconnected');
    sesh.disconnected();
    setPresence('disconnected');
}

export const newGame = (e) => {
    // debugger;
    if (e.target.disabled) return false;
    console.log('New game requested');
    // e.target.disabled = true;
    // if (menuDisabled) return false;

    // setMenuDisabled(true);
    // setPending(true);
    if (e) {
        e.stopPropagation();

        if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
            e.nativeEvent.stopPropagation();
        }

        if (e.target) {
            e.target.attributes['disabled'] = true;
            e.target.disabled = true;
            e.target.onClick = null;
        }
    } else if (Event) {
        Event.stopPropagation()
    }

    (async () => {
        //debugger;
        var data = {
            // playerName: name,
            // homeCountryCode: homeCountryCode,
            colour: 'none' // colour
        };
        var response = await fetch('/api/gamedata/new', {
            method: 'POST',
            credentials: "same-origin",
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain, */*' },
            body: JSON.stringify(data)
        });

        //debugger;
        const content = await response.json();

        if (content != null) {
            if (response.status === 200) {
                console.log(content.principalGameUrl);

                var parser = document.createElement('a');
                parser.href = content.principalGameUrl;
                console.log(parser.pathname);
                var path = parser.pathname;
                if (!path.startsWith('/') && !path.startsWith('http')) path = '/' + path;
                // props.history.push(path);
                window.location = path; // TODO: do this proper
            } else {
                console.log(content);
            }
            //debugger;
        }
    })();

    return false;
}

const sesh = session;

const gameHub = {
    games: Games,
    gameState: GameState,
    server: sesh,
    // gameNotifiers: GameNotifiers,
    addGameNotifier: function (id, newNotifier) { handleAddGameNotifier(id, newNotifier); },
    load: function (gameId, gamerId, callback) { gameLoad(gameId, gamerId, callback); },
    online: function (gameId, gamerId) { online(gameId, gamerId); },
    away: function (gameId, gamerId) { away(gameId, gamerId); },
    disconnected: function (gameId, gamerId) { disconnected(gameId, gamerId); },
    refresh: function (gameId, gamerId) { sesh.refresh(gameId, gamerId); },

    list: function (pageIndex, pageSize, callback) { sesh.list(pageIndex, pageSize, callback); },
    joinGame: function (gameId, gamerId) { sesh.joinGame(gameId, gamerId); },
    joinAI: function (gameId, gamerId, playerId) { sesh.joinAI(gameId, gamerId, playerId); },
    joinVote: function (gameId, gamerId, minVotes, max, maxVoteChoice, timeLimit) { sesh.joinVote(gameId, gamerId, minVotes, max, maxVoteChoice, timeLimit); },
    register: function (gameId, gamerId) { sesh.register(gameId, gamerId); },
    vote: function (gameId, gamerId, voteId, moveId, pieceId, from, to, choice, callback) { sesh.vote(gameId, gamerId, voteId, moveId, pieceId, from, to, choice, callback); },
    move: function (gameId, gamerId, pieceId, from, to, choice, callback) { sesh.move(gameId, gamerId, pieceId, from, to, choice, callback); },


    // online: function(gameId, gamerId) { online(gameId, gamerId); }
}

export default gameHub;
var initted = false;
const init = () => {
    if (!initted) {
        sesh.addGameNotifier('gameHub', function (g) { handleGameUpdated(g); });
    }

    initted = true;
}



/*==============
Context
==============*/
const GameHubContext = createContext(gameHub);
export const useGameHubCtx = () => useContext(GameHubContext);
export const useGameHubCtxState = () => {
    const [state] = useContext(GameHubContext);
    return state;
};
export const GameCtxProvider = ({ children, init }) => {
    const gameCtx = useState(gameHub);
    return <GameHubContext.Provider value={gameCtx}>{children}</GameHubContext.Provider>
}
export const GameCtxConsumer = ({ children, init }) => {
    const gameCtx = useState(gameHub);
    return <GameHubContext.Consumer value={gameCtx}>{children}</GameHubContext.Consumer>
}

init();