import React, { Component } from 'react'
import { Move } from './Move'
import { Vote } from './Vote'
import Host from './Host'
import { useGameHubCtx } from './GameHub'
import { Piece } from './Piece'
import { ConnectionStatus } from './GameServer'
import './Status.css'
import Moves from './Moves'

export const Status = (props) => {
    const gameHub = useGameHubCtx();
    // const gameState = gameHub.gameState[gameId];

    const gameHandle = props.gameHandle;
    const game = gameHandle.game;
    const me = game.me;
    const gameId = game.id;
    const [showSidebar, setShowSidbar] = React.useState(false);

    // const [buttonTimerFire, setButtonTimerFired] = React.useState(false);
    // var buttonPressTimer;
    // const handleButtonPress = (e) => {
    //     setButtonTimerFired(false);
    //     buttonPressTimer = setTimeout(() => {
    //         setButtonPressTimerFired(true);
    //         gameHandle.onTap(e, true);
    //     }, 1500);
    // };

    // const handleButtonRelease = (e) => {
    //     if (!buttonPressTimer) return;
    //     if (buttonPressTimerFired) return;

    //     clearTimeout(buttonPressTimer);
    //     gameHandle.onTap(e, false);
    //     setButtonPressTimerFired(false);
    // };

    const toggleSidebar = () =>
    {
        setShowSidbar(!showSidebar);
    }

    const renderVote = () =>
    {
        if (!me.canVote)
        {
            return;
        }

        var game = gameHub.games[gameId];
        var lastVote = game.me.lastVote;
        

        return (<li>
                    <h4>Last Vote</h4>
                    <ul>
                        <Vote gameHandle={gameHandle} vote={lastVote} />
                    </ul>
                </li>);
    }

    const renderHost = () =>
    {
        var host = game.host;
        if (!host)
        {
            return;
        }

        return (<li>
                    <h4>Host</h4>
                    <Host gameHandle={gameHandle} name={host.name} countryCode={host.homeCountryCode} />
                </li>);
    }

    const renderServer = () =>
    {
        // return (<li onTouchStart={handleButtonPress} onTouchEnd={handleButtonRelease} onMouseDown={handleButtonPress} onMouseUp={handleButtonRelease} onMouseLeave={handleButtonRelease}>
        return (<li onClick={handleRefresh}>
            <h4>Server</h4>
            <ConnectionStatus />
        </li>);
    }

    const renderSidebar = () => 
    {
        if (showSidebar)
        {
            return (<div className="sidebar">
                <Moves gameHandle={gameHandle} />
            </div>);
        }
    }

    const handleRefresh = () =>
    {
        var gamerId = gameHandle.game.principal.gamerId;
        gameHub.refresh(gameId, gamerId);
    }

    const renderLastMove = () =>
    {
        const lastMove = game.lastMove;
        var move = <li>No move</li>;
        if (lastMove) {
            move = <Move gameHandle={gameHandle} moveId={lastMove.id} pieceRef={lastMove.pieceRef} tile={lastMove.tileRef} />
        }
        
        return (
            <li>
                <h4 onClick={toggleSidebar}>Last Move</h4>
                <ul>
                    {move}
                </ul>
            </li>
        );
    }

    const printBoardLayout = () => 
    {
        // const board = game.board;
        var pieces = game.pieces;
        for (var pieceId in pieces)
        {
            var piece = pieces[pieceId];
            console.log(`game.AddPiece(${piece.colour}Player, Piece.${piece.type}, ${piece.tile})`);
        }
    }

    return (
        <>
        <ul className="status">
            {renderLastMove()}
            {renderVote()}
            {renderHost()}
            {renderServer()}
        </ul>
        {renderSidebar()}
        </>
    );
}
export default Status;