import React, { Component } from 'react';
import Opponent from './Opponent';
import Setup from './Setup';
import Voter from './Voter';

import Player from './Player'

export const GamePlayer = (props) => {
    
    const gamerId = props.gamerId;
    const gameHandle = props.gameHandle;
    const game = gameHandle.game;
    const me = game.me;
    var gamer = game.players[gamerId];
    var player = (<></>);

    var gamerName = gamer.name;
    var gamerCountryCode = gamer.countryCode;

    if (game.status === "Setup" && gamer && !gamer.hasPlayer) { //  && me.playerName == host.playerName
        player = (<><Setup gameHandle={gameHandle} gamerId={gamerId} /></>);
    }
    else if (gamer && gamer.isVoter)
    {
        player = (<Voter gameHandle={gameHandle} gamerId={gamerId} />);
        if (me.currentVote && me.currentVote.gamerId === gamer.id)
        {
            gamerName = me.name;
            gamerCountryCode = me.homeCountryCode;
        }
    }
    else
    {
        player = (<Opponent gameHandle={gameHandle} gamer={gamer} gamerId={gamerId} />);
    }

    return (<div className="vertical">
                <Player gameHandle={gameHandle} name={gamerName} countryCode={gamerCountryCode} url={gamer.gamerUrl} colour={gamer.colour} />
                {player}
            </div>
        );
}

export default GamePlayer;