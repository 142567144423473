import { ThemeProvider, useRadioGroup } from '@mui/material';
import React, { Component, useContext, useEffect } from 'react'
import { createContext } from 'react';
import { useConfigCtx  } from './Config';
import { useNotifyCtx  } from './Notification';
import './Settings.css'

export const Settings = (props) => {
    const configCtx = useConfigCtx();
    const notifyCtx = useNotifyCtx();
    const [rtheme, rsetTheme] = React.useState(configCtx.theme);
    const [rboard, rsetBoard] = React.useState(configCtx.board);
    const [rset, rsetSet] = React.useState(configCtx.set);
    // const [rnotification, rSetNotification] = React.useState(configCtx.set);

    const settingsConfigUpdate = (newConfig) => {
        rsetTheme(newConfig.theme);
        rsetBoard(newConfig.board);
        rsetSet(newConfig.set);
    }

    configCtx.addNotifier('settings', settingsConfigUpdate);
    const handleBoard = (newBoard) => {
        console.log('Settings: setting the board from ' + rboard + ' to ' + newBoard);
        
        configCtx.setBoard(newBoard);
    }

    const handleTheme = (newTheme) => {
        console.log('Settings: setting the theme from ' + rtheme + ' to ' + newTheme);

        configCtx.setTheme(newTheme);
    }

    const handleSet = (newSet) => {
        console.log('Settings: setting the set from ' + rset + ' to ' + newSet);

        configCyx.setSet(newSet);
    }

    
    var now = new Date();
    var nowfmt = now.toLocaleTimeString();

    return ( 
        <div className="settings">
            Board<br /><br />
            <ul>
                <li onClick={(e) => { handleBoard('grey') }} className={configCtx.board === 'grey' ? 'selected' : ''}>Grey</li>
                <li onClick={(e) => { handleBoard('classic') }} className={configCtx.board === 'classic' ? 'selected' : ''}>Classic</li>
            </ul>
            <br />
            <br />

            Theme<br /><br />
            <ul>
                <li onClick={(e) => { handleTheme('light') }} className={configCtx.theme === 'light' ? 'selected' : ''}>Light</li>
                <li onClick={(e) => { handleTheme('dark') }} className={configCtx.theme === 'dark' ? 'selected' : ''}>Dark</li>
            </ul>
            <br /><br />
            {notifyCtx.supportsNotifications ? (
            <>
            Notifications
            <h5>Notifications do not work for every device</h5><br />
            <br /><br />
            <ul>
                <li onClick={(e) => { notifyCtx.handleNotification('off') }} className={notifyCtx.notifications === 'off' ? 'selected' : ''}>Off</li>
                <li onClick={(e) => { notifyCtx.handleNotification('on') }} className={notifyCtx.notifications === 'on' ? 'selected' : ''}>On</li>
            </ul>
            </>
            ) : null}
            
            {notifyCtx.supportsNotifications && notifyCtx.notifications === 'on' ? 
            <ul>
                <li onClick={(e) => { testNotifications() }}>Test</li>
                Test your notifications<br />
                If the notifications don't appear then try turning it off and on again
                If that doesnt work then we likely dont support your device right now<br />
            </ul>
            : null}
            <br/>
            <br/>
            {notifyCtx.message ? <b>{nowfmt}:{notifyCtx.message}</b> : null }
            

        </div>
    );
}
