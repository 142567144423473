import React, { Component } from 'react';

export class Privacy extends Component {
//   displayName = Home.name

  render() {
      return (
          <div className="home">
             <div className="row">
                <h1>Privacy Policy</h1>
              </div>
              <div className="row">
                <h2>Overview</h2>
                This sets out the privacy policy for Squarefight (the Platform)

                <h2>You</h2>
                <h3>Data</h3>
                <li>You provide your consent to us in order to store any data provided by you</li>
                <li>You must have the necessary consent from the data owner before submitting data to the Platform</li>

                <h3>Purpose</h3>
                <li>We do not sell any personally identifiable information (PII)</li>
                <li>We aggregate some Non-PII data which is used to improve the site, provide additional services and for marketing, sales and training activities</li>
                <li>We use first party and third party advertising to support this website</li>
                <li>Data may be accessed during maintenance and to resolve any technical or non-technical issues</li>

                <h3>Security</h3>
                <li>The Platform uses cookies for security and to provide you with services and functionality</li>
                <li>The Platform uses certain advanced features on your device which may required additional permissions from you before they can work</li>

                <h3>Actions</h3>
                <li>Commit to being a good citizen on the Platform</li>
                <li>Commit to maintain your details to ensure the security of everyone on the Platform</li>

                <h2>The people</h2>
                <li>We do not screen or verify users, be sure you know and trust the people you connect with on the Platform</li>
                <li>Be respectful when dealing with others</li>

                <h2>The Platform</h2>
                <h3>Data</h3>
                <li>Data in transit is encrypted</li>
                <li>Data is stored encrypted</li>
                <li>Data is held on servers in Europe</li>
                <li>Data is held until it is no longer needed to provide you with services or to comply with legal requirements</li>
                <li>We continually monitor our systems to ensure compliance with our terms and conditions, support law enforcement activities and to prevent abuse of our systems and its users</li>

                <h3>Abuse</h3>
                <li>The Platform lets you connect with other people, please ensure you trust anyone you interact with on it</li>
                <li>We do not tolerate abuse of your fellow users</li>
                <li>We do not tolerate abuse of staff or anyone connected with the Platform</li>
                <li>We do not tolerate users circumventing the security and integrity of the Platform</li>
                <li>If you violate these policies, your services may be limited and you may be permanently banned</li>

                <h2>Services</h2>
                <li>We utilise a number of third party services to enhance the functionality of the Platform</li>
                <li>This includes, but is not limited to:
                    <ul>
                        <li>Push Notifications</li>
                        <li>Marketing</li>
                    </ul>
                </li>
                <li>We reserve the right to remove services at any time</li>
                <li>Services may be limited or removed on a per account basis</li>
                <li>This site is supported by first party and third party advertising</li>
              </div>
          </div>
    );
  }
}
