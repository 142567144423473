import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useUserCtxState, useUserCtx } from './User';
import './Profile.css'

export const Profile = (props) => {
    const [open, setOpen] = React.useState(false);
    const[error, setError] = React.useState('');
    
    const[user, setUser] = useUserCtx();
    const profileUserUpdate = (user) => {
        console.log('profileUserUpdate ' + user.playerName + ', ' + user.homeCountryCode);
        setPlayerName(user.playerName);
        setHomeCountryCode(user.homeCountryCode);
    }
    user.addNotifier('profile', profileUserUpdate);
    const[playerName, setPlayerName] = React.useState(user.playerName);
    const[homeCountryCode, setHomeCountryCode] = React.useState(user.homeCountryCode);


    const handleProfileOpen = () => {
        setOpen(true);
        // console.log(homeCountryCode);
        // console.log(playerName);
    };

    const updateProfile = (e) => {
        console.log('update profile');

        var success = function(json)
        {
            setOpen(false);
        }
        var error = function (err)
        {
            if (err.PlayerName) setError(err.PlayerName[0]);
            if (err.HomeCountryCode) setError(err.HomeCountryCode[0]);
            
        }

        if (playerName.length < 5) {
            setError('Name too short');
            return;
        }
        if (playerName.length > 30) {
            setError('Name too long');
            return;
        }
        if (!playerName.match('^[a-zA-Z][a-zA-Z0-9_]{3,}[a-zA-Z0-9]$')) // /^[a-z0-9][a-z0-9]+$/i
        {
            setError('Name can only have letters and numbers');
            return;
        }

        user.updateProfile(playerName, homeCountryCode, { success, error });
        
        // window.location.reload();
    };

    const handleCancel = () => {
        setOpen(false); 
    };

    const handlePlayerNameChange = (e) => {
        var val = e.target.value;

        if (val.length > 30)
        {
            e.preventDefault();
            // e.target.value = val.substring(0, 30);
            setError('Name too long');
            return false;
        }
        if (val.length < 5)
        {
            e.preventDefault();
            setError('Name too short');
            return false;
        }
        if (!val.match('^[a-zA-Z][a-zA-Z0-9_]{3,}[a-zA-Z0-9]$')) // /^[a-z0-9][a-z0-9]+$/i
        {
            e.preventDefault();
            setError('Name can only have letters and numbers');
            return;
        }
        // if (!val.match('/^[a-z0-9]+$/i')) return false;
        setPlayerName(val);
        setError('');
    }

    const handleCountryChange = (e) => {
        setHomeCountryCode(e.target.value);
    }

    const showTitle = () => {
        return gameHandle ? "To Vote" : ""
    }

    return (
        <div>
            <IconButton color="inherit" onClick={handleProfileOpen} aria-label="update profile">
                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="7" r="4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4 21V17C4 15.8954 4.89543 15 6 15H18C19.1046 15 20 15.8954 20 17V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </IconButton>
            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>Profile</DialogTitle>
                <DialogContent>
                    {error.length>0?<span className="error">{error}</span>:null}
                    <form>
                        <FormControl fullWidth>
                            {/* <InputLabel id="maxVotes">Max Votes</InputLabel> */}
                            <TextField
                                id="playerName"
                                defaultValue={user.playerName}
                                label="Name"
                                onChange={handlePlayerNameChange}
                                required
                                
                            >
                                
                            </TextField>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                            <InputLabel id="homeCountryCodeLabel">Country</InputLabel>
                            <Select
                                labelId="homeCountryCodeLabel"
                                id="homeCountryCode"
                                defaultValue={user.homeCountryCode}
                                label="Country"
                                onChange={handleCountryChange}
                            >
                                <MenuItem value={"AF"}>Afghanistan</MenuItem>
                                <MenuItem value={"AL"}>Albania</MenuItem>
                                <MenuItem value={"DZ"}>Algeria</MenuItem>
                                <MenuItem value={"AS"}>American Samoa</MenuItem>
                                <MenuItem value={"AD"}>Andorra</MenuItem>
                                <MenuItem value={"AO"}>Angola</MenuItem>
                                <MenuItem value={"AG"}>Antigua and Barbuda</MenuItem>
                                <MenuItem value={"AR"}>Argentina</MenuItem>
                                <MenuItem value={"AM"}>Armenia</MenuItem>
                                <MenuItem value={"AU"}>Australia</MenuItem>
                                <MenuItem value={"AT"}>Austria</MenuItem>
                                <MenuItem value={"AZ"}>Azerbaijan</MenuItem>
                                <MenuItem value={"BS"}>Bahamas</MenuItem>
                                <MenuItem value={"BH"}>Bahrain</MenuItem>
                                <MenuItem value={"BD"}>Bangladesh</MenuItem>
                                <MenuItem value={"BB"}>Barbados</MenuItem>
                                <MenuItem value={"BY"}>Belarus</MenuItem>
                                <MenuItem value={"BE"}>Belgium</MenuItem>
                                <MenuItem value={"BZ"}>Belize</MenuItem>
                                <MenuItem value={"BJ"}>Benin</MenuItem>
                                <MenuItem value={"BM"}>Bermuda</MenuItem>
                                <MenuItem value={"BT"}>Bhutan</MenuItem>
                                <MenuItem value={"BO"}>Bolivia</MenuItem>
                                <MenuItem value={"BA"}>Bosnia and Herzegovina</MenuItem>
                                <MenuItem value={"BW"}>Botswana</MenuItem>
                                <MenuItem value={"BR"}>Brazil</MenuItem>
                                <MenuItem value={"BN"}>Brunei</MenuItem>
                                <MenuItem value={"BG"}>Bulgaria</MenuItem>
                                <MenuItem value={"BF"}>Burkina Faso</MenuItem>
                                <MenuItem value={"BU"}>Burma</MenuItem>
                                <MenuItem value={"BI"}>Burundi</MenuItem>
                                <MenuItem value={"KH"}>Cambodia</MenuItem>
                                <MenuItem value={"CM"}>Cameroon</MenuItem>
                                <MenuItem value={"CA"}>Canada</MenuItem>
                                <MenuItem value={"CV"}>Cape Verde</MenuItem>
                                <MenuItem value={"CF"}>Central African Republic</MenuItem>
                                <MenuItem value={"TD"}>Chad</MenuItem>
                                <MenuItem value={"CL"}>Chile</MenuItem>
                                <MenuItem value={"CN"}>China</MenuItem>
                                <MenuItem value={"CO"}>Colombia</MenuItem>
                                <MenuItem value={"KM"}>Comoros</MenuItem>
                                <MenuItem value={"CD"}>Congo</MenuItem>
                                <MenuItem value={"CR"}>Costa Rica</MenuItem>
                                <MenuItem value={"CI"}>Côte d'Ivoire (Ivory Coast)</MenuItem>
                                <MenuItem value={"HR"}>Croatia</MenuItem>
                                <MenuItem value={"CU"}>Cuba</MenuItem>
                                <MenuItem value={"CY"}>Cyprus</MenuItem>
                                <MenuItem value={"CZ"}>Czech Republic</MenuItem>
                                <MenuItem value={"DK"}>Denmark</MenuItem>
                                <MenuItem value={"DJ"}>Djibouti</MenuItem>
                                <MenuItem value={"DM"}>Dominica</MenuItem>
                                <MenuItem value={"DO"}>Dominican Republic</MenuItem>
                                <MenuItem value={"EC"}>Ecuador</MenuItem>
                                <MenuItem value={"EG"}>Egypt</MenuItem>
                                <MenuItem value={"SV"}>El Salvador</MenuItem>
                                <MenuItem value={"EK"}>Equatorial Guinea</MenuItem>
                                <MenuItem value={"ER"}>Eritrea</MenuItem>
                                <MenuItem value={"EE"}>Estonia</MenuItem>
                                <MenuItem value={"ET"}>Ethiopia</MenuItem>
                                <MenuItem value={"FJ"}>Fiji</MenuItem>
                                <MenuItem value={"FI"}>Finland</MenuItem>
                                <MenuItem value={"FR"}>France</MenuItem>
                                <MenuItem value={"GA"}>Gabon</MenuItem>
                                <MenuItem value={"GM"}>Gambia</MenuItem>
                                <MenuItem value={"GE"}>Georgia</MenuItem>
                                <MenuItem value={"DE"}>Germany</MenuItem>
                                <MenuItem value={"GH"}>Ghana</MenuItem>
                                <MenuItem value={"GR"}>Greece</MenuItem>
                                <MenuItem value={"GD"}>Grenada</MenuItem>
                                <MenuItem value={"GT"}>Guatemala</MenuItem>
                                <MenuItem value={"GN"}>Guinea</MenuItem>
                                <MenuItem value={"GW"}>Guinea-Bissau</MenuItem>
                                <MenuItem value={"GY"}>Guyana</MenuItem>
                                <MenuItem value={"HT"}>Haiti</MenuItem>
                                <MenuItem value={"VA"}>Holy See</MenuItem>
                                <MenuItem value={"HN"}>Honduras</MenuItem>
                                <MenuItem value={"HU"}>Hungary</MenuItem>
                                <MenuItem value={"IS"}>Iceland</MenuItem>
                                <MenuItem value={"IN"}>India</MenuItem>
                                <MenuItem value={"ID"}>Indonesia</MenuItem>
                                <MenuItem value={"IR"}>Iran</MenuItem>
                                <MenuItem value={"IQ"}>Iraq</MenuItem>
                                <MenuItem value={"IE"}>Ireland</MenuItem>
                                <MenuItem value={"IL"}>Israel</MenuItem>
                                <MenuItem value={"IT"}>Italy</MenuItem>
                                <MenuItem value={"JM"}>Jamaica</MenuItem>
                                <MenuItem value={"JP"}>Japan</MenuItem>
                                <MenuItem value={"JO"}>Jordan</MenuItem>
                                <MenuItem value={"KZ"}>Kazakhstan</MenuItem>
                                <MenuItem value={"KE"}>Kenya</MenuItem>
                                <MenuItem value={"KI"}>Kiribati</MenuItem>
                                <MenuItem value={"KW"}>Kuwait</MenuItem>
                                <MenuItem value={"KG"}>Kyrgyzstan</MenuItem>
                                <MenuItem value={"LA"}>Laos</MenuItem>
                                <MenuItem value={"LV"}>Latvia</MenuItem>
                                <MenuItem value={"LB"}>Lebanon</MenuItem>
                                <MenuItem value={"LS"}>Lesotho</MenuItem>
                                <MenuItem value={"LR"}>Liberia</MenuItem>
                                <MenuItem value={"LY"}>Libya</MenuItem>
                                <MenuItem value={"LI"}>Liechtenstein</MenuItem>
                                <MenuItem value={"LT"}>Lithuania</MenuItem>
                                <MenuItem value={"LU"}>Luxembourg</MenuItem>
                                <MenuItem value={"MK"}>Macedonia</MenuItem>
                                <MenuItem value={"MG"}>Madagascar</MenuItem>
                                <MenuItem value={"MW"}>Malawi</MenuItem>
                                <MenuItem value={"MY"}>Malaysia</MenuItem>
                                <MenuItem value={"MV"}>Maldives</MenuItem>
                                <MenuItem value={"ML"}>Mali</MenuItem>
                                <MenuItem value={"MT"}>Malta</MenuItem>
                                <MenuItem value={"MH"}>Marshall Islands</MenuItem>
                                <MenuItem value={"MR"}>Mauritania</MenuItem>
                                <MenuItem value={"MU"}>Mauritius</MenuItem>
                                <MenuItem value={"MX"}>Mexico</MenuItem>
                                <MenuItem value={"FM"}>Micronesia Federated States</MenuItem>
                                <MenuItem value={"MD"}>Moldova</MenuItem>
                                <MenuItem value={"MC"}>Monaco</MenuItem>
                                <MenuItem value={"MN"}>Mongolia</MenuItem>
                                <MenuItem value={"MA"}>Morocco</MenuItem>
                                <MenuItem value={"MZ"}>Mozambique</MenuItem>
                                <MenuItem value={"NA"}>Namibia</MenuItem>
                                <MenuItem value={"NR"}>Nauru</MenuItem>
                                <MenuItem value={"NP"}>Nepal</MenuItem>
                                <MenuItem value={"NL"}>Netherlands</MenuItem>
                                <MenuItem value={"NZ"}>New Zealand</MenuItem>
                                <MenuItem value={"NI"}>Nicaragua</MenuItem>
                                <MenuItem value={"NE"}>Niger</MenuItem>
                                <MenuItem value={"NG"}>Nigeria</MenuItem>
                                <MenuItem value={"KP"}>North Korea</MenuItem>
                                <MenuItem value={"NO"}>Norway</MenuItem>
                                <MenuItem value={"OM"}>Oman</MenuItem>
                                <MenuItem value={"PK"}>Pakistan</MenuItem>
                                <MenuItem value={"PW"}>Palau</MenuItem>
                                <MenuItem value={"PA"}>Panama</MenuItem>
                                <MenuItem value={"PG"}>Papua New Guinea</MenuItem>
                                <MenuItem value={"PY"}>Paraguay</MenuItem>
                                <MenuItem value={"PE"}>Peru</MenuItem>
                                <MenuItem value={"PH"}>Philippines</MenuItem>
                                <MenuItem value={"PL"}>Poland</MenuItem>
                                <MenuItem value={"PT"}>Portugal</MenuItem>
                                <MenuItem value={"QA"}>Qatar</MenuItem>
                                <MenuItem value={"RO"}>Romania</MenuItem>
                                <MenuItem value={"RU"}>Russia</MenuItem>
                                <MenuItem value={"RW"}>Rwanda</MenuItem>
                                <MenuItem value={"KN"}>Saint Kitts and Nevis</MenuItem>
                                <MenuItem value={"LC"}>Saint Lucia</MenuItem>
                                <MenuItem value={"VC"}>Saint Vincent and the Grenadines</MenuItem>
                                <MenuItem value={"WS"}>Samoa</MenuItem>
                                <MenuItem value={"SM"}>San Marino</MenuItem>
                                <MenuItem value={"ST"}>Sao Tome and Principe</MenuItem>
                                <MenuItem value={"SA"}>Saudi Arabia</MenuItem>
                                <MenuItem value={"SN"}>Senegal</MenuItem>
                                <MenuItem value={"SC"}>Seychelles</MenuItem>
                                <MenuItem value={"SL"}>Sierra Leone</MenuItem>
                                <MenuItem value={"SG"}>Singapore</MenuItem>
                                <MenuItem value={"SK"}>Slovakia</MenuItem>
                                <MenuItem value={"SI"}>Slovenia</MenuItem>
                                <MenuItem value={"SB"}>Solomon Islands</MenuItem>
                                <MenuItem value={"SO"}>Somalia</MenuItem>
                                <MenuItem value={"ZA"}>South Africa</MenuItem>
                                <MenuItem value={"KR"}>South Korea</MenuItem>
                                <MenuItem value={"ES"}>Spain</MenuItem>
                                <MenuItem value={"LK"}>Sri Lanka</MenuItem>
                                <MenuItem value={"SD"}>Sudan</MenuItem>
                                <MenuItem value={"SR"}>Suriname</MenuItem>
                                <MenuItem value={"SZ"}>Swaziland</MenuItem>
                                <MenuItem value={"SE"}>Sweden</MenuItem>
                                <MenuItem value={"CH"}>Switzerland</MenuItem>
                                <MenuItem value={"SY"}>Syria</MenuItem>
                                <MenuItem value={"TW"}>Taiwan</MenuItem>
                                <MenuItem value={"TJ"}>Tajikistan</MenuItem>
                                <MenuItem value={"TZ"}>Tanzania</MenuItem>
                                <MenuItem value={"TH"}>Thailand</MenuItem>
                                <MenuItem value={"TG"}>Togo</MenuItem>
                                <MenuItem value={"TO"}>Tonga</MenuItem>
                                <MenuItem value={"TT"}>Trinidad and Tobago</MenuItem>
                                <MenuItem value={"TN"}>Tunisia</MenuItem>
                                <MenuItem value={"TR"}>Turkey</MenuItem>
                                <MenuItem value={"TX"}>Turkmenistan</MenuItem>
                                <MenuItem value={"TV"}>Tuvalu</MenuItem>
                                <MenuItem value={"UG"}>Uganda</MenuItem>
                                <MenuItem value={"UA"}>Ukraine</MenuItem>
                                <MenuItem value={"AE"}>United Arab Emirates</MenuItem>
                                <MenuItem value={"UK"}>United Kingdom</MenuItem>
                                <MenuItem value={"US"}>United States</MenuItem>
                                <MenuItem value={"UY"}>Uruguay</MenuItem>
                                <MenuItem value={"UZ"}>Uzbekistan</MenuItem>
                                <MenuItem value={"VU"}>Vanuatu</MenuItem>
                                <MenuItem value={"VE"}>Venezuela</MenuItem>
                                <MenuItem value={"VN"}>Vietnam</MenuItem>
                                <MenuItem value={"YE"}>Yemen</MenuItem>
                                <MenuItem value={"YU"}>Yugoslavia</MenuItem>
                                <MenuItem value={"ZM"}>Zambia</MenuItem>
                                <MenuItem value={"ZW"}>Zimbabwe</MenuItem>
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={updateProfile}>Update</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// export Profile;
export default Profile;