import React from 'react';
import { Layout } from './components/Layout';
import Advert from './components/Advert';
import Routing from './routes';
import { CookieLover } from './components/CookieLover';
import { Debug } from './components/Debug';

import './sets/classic/classic.css';
function App() {
    return (
        <div className="App">
        {/* <Debug /> */}
        <CookieLover />
        <Layout >
            <Advert  />
            <Routing />
            <Advert />
        </Layout>
        </div>
    );
}

export default App;
