import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export const JoinAI = (props) => {
    // var state;
    const [open, setOpen] = React.useState(false);
    // debugger;
    const gameHandle = props.gameHandle;
    const gameId = gameHandle.game.id;
    const gamerId = props.gamerId;

    var selectedId = props.selectedPlayerId;
    var stateObj = { selectedId: selectedId };
    const [cbState, setCbState] = React.useState(stateObj);    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleJoin = () => {
        if (!cbState.selectedId)
        {
            console.log("no AI player selected");
            return;
        }

        gameHandle.setPending(true);

        props.gameHandle.gameHub.joinAI(gameId, gamerId, cbState.selectedId);

        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false); 
    };


    const handlePlayerChange = (e) => {
        selectedId = e.target.value;
    }

    const setPlayer = (playerId) => {
        console.log('setting the AI to ' + playerId);

        setCbState({ ...cbState, selectedId: playerId });
    }

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen} color="success">
                Add AI
            </Button>
            <h5>join an AI</h5>
            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>Add AI</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Pick an AI player
                    </DialogContentText>
                    <form>
                        <div className="settings">
                        <ul>
                            <li onClick={(e) => { setPlayer('69696969-6969-6969-6969-696969696969') }} className={cbState.selectedId === '69696969-6969-6969-6969-696969696969' ? 'selected' : ''}>Randy</li>
                        </ul>
                        </div>
                    </form>
                    <i>Randy is great but he also has no idea what he's doing so if you want a real challenge then stick to humans for now
                    <br /><br/>Some of Randy's AI friends are on their way, they are just stuck in traffic right now</i>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleJoin}>Join</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default JoinAI;