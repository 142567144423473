import React, { Component } from 'react';
import Tile from './Tile';
import { useConfigCtx, useConfigCtxState } from './Config';
import { render } from 'react-dom';
import './Board.css'

export const Board = (props) => {
    const boardConfigUpdate = (newConfig) => {
        setBoardStyle(newConfig.board);
    }
    const configCtx = useConfigCtx();
    configCtx.addNotifier('board', boardConfigUpdate);
    const [boardStyle, setBoardStyle] = React.useState(configCtx.board);

    // const renderTile = (tile) => {
    //     return <Tile key={tile.reference} tile={tile} reference={tile.reference} gameHandle={props.gameHandle} type={tile.type} pieceRef={tile.pieceRef} />;
    // }

    const renderRows = (orientation) => 
    {
        let rows = [];

        switch (orientation.toLowerCase()) {
            case "white":
                for (let r = board.rows - 1; r >= 0; r--) {
                    let cols = [];
                    for (let c = 0; c < board.cols; c++) {
                        var tile = board.tiles[c][r];
                        if (tile) {
                            cols.push(<Tile key={tile.reference} tile={tile} reference={tile.reference} gameHandle={props.gameHandle} type={tile.type} pieceRef={tile.pieceRef} />);
                        } else {
                            cols.push(<Tile key={tile.reference} tile={tile} reference={tile.reference} gameHandle={props.gameHandle} />);
                        }
                    }

                    rows.push(<tr key={'row' + r}>{cols}</tr>);
                }
                break;
            case "black":
                for (let r = 0; r < board.rows; r++) {
                    let cols = [];
                    for (let c = board.cols - 1; c >= 0; c--) {
                        var tile2 = board.tiles[c][r];
                        if (tile2) {
                            cols.push(<Tile key={tile2.reference} tile={tile2} reference={tile2.reference} gameHandle={props.gameHandle} type={tile2.type} pieceRef={tile2.pieceRef} />);
                        } else {
                            cols.push(<Tile key={tile2.reference} tile={tile2} reference={tile2.reference} gameHandle={props.gameHandle} />);
                        }

                    }

                    rows.push(<tr key={'row' + r}>{cols}</tr>);
                }
                break;
            case "red":
                for (let c = 0; c < board.cols; c++) {
                    let cols = [];
                    for (let r = 0; r < board.rows; r++) {
                        var tile3 = board.tiles[c][r];
                        if (tile3) {
                            cols.push(<Tile key={tile3.reference} tile={tile3} reference={tile3.reference} gameHandle={props.gameHandle} type={tile3.type} pieceRef={tile3.pieceRef} />);
                        } else {
                            cols.push(<Tile key={tile3.reference} tile={tile3} reference={tile3.reference} gameHandle={props.gameHandle} />);
                        }

                    }

                    rows.push(<tr key={'col' + c}>{cols}</tr>);
                }
                break;
            case "blue":
                for (let c = board.cols - 1; c >= 0; c--) {
                    let cols = [];
                    for (let r = board.rows - 1; r >= 0; r--) {
                        var tile4 = board.tiles[c][r];
                        if (tile4) {
                            cols.push(<Tile key={tile4.reference} tile={tile4} reference={tile4.reference} gameHandle={props.gameHandle} type={tile4.type} pieceRef={tile4.pieceRef} />);
                        } else {
                            cols.push(<Tile key={tile4.reference} tile={tile4} reference={tile4.reference} gameHandle={props.gameHandle} />);
                        }

                    }

                    rows.push(<tr key={'col' + c}>{cols}</tr>);
                }
                break;
            default:
                break;
        }

        return rows;
    }

    var game = props.gameHandle.game;
    var board = game.board;
    var principal = game.principal;

    if (!board) {
        return (<h3>Loading..</h3>);
    }

    let rows = [];
    return (
        <table className={["board", boardStyle, board.cols > 8 ? 'expanded' : ''].join(' ')}>
            <tbody>{renderRows(principal.colour)}</tbody>
        </table>
    );
}

export default Board;