import React, { Component } from 'react';

export const CookieLover = (props) => {

    var cookiePresent = document.cookie.indexOf("consented") !== -1;
    const [consented, setConsented] = React.useState(cookiePresent);

    const consent = () =>
    {
        var domain = window.location.origin;
        // document.cookie='consented=true; expires=Thu, 18 Dec 2045 12:00:00 UTC; domain=' + domain + '; path=/'; 
        document.cookie='consented=true; path=/; expires=Thu, 18 Dec 2045 12:00:00 UTC;'; 

        document.getElementById('cookies').style.display = "none";
        setConsented(true);
        return false;
    }
  
    return (
      consented ? null :
      <div id="cookies">
          Do you like cookie?<br />
          <br/>
          <a onClick={consent} className="pos">I like cookie</a>
          <br/>
          <br/>
          <br/>
          If you delete or clear your cookies you will lose your profile and game history<br/>
          Registrations are coming soon<br/>
          <br/> 
          <br/>
          <br/>
          <a className="neg" target="_blank" href="https://gdpr.eu/cookies/">Those cookies are a bit too baked</a>
      </div>
  );
}
