import React, { Component } from 'react';
// import GamePlayer from './GamePlayer';
import Register from './Register';
import AddPiece from './AddPiece';
import Profile from './Profile';
import { useGameHubCtx } from './GameHub'

export const Voter = (props) => {
    var gamerId = props.gamerId;
    var gameHandle = props.gameHandle;
    
    const gameHub = useGameHubCtx();
    var game = gameHub.games[gameHandle.game.id];

    var gameId = game.id;
    var currentVote = game.me.currentVote;

    React.useEffect(() => {
            const refreshTimer = setInterval(
                    () => { 
                        var vote = game.me.currentVote;
                        if (vote)
                        {
                            var softTime = determineSoftDueTime(vote);
                            setSoftDate(softTime);
                        }
                        else
                        {
                            setSoftDate(null);
                        }
                    }, 1000
                );

            return () => {
                clearInterval(refreshTimer);
            }
        });
    // }, [game.lastUpdated]);

    const determineSoftDueTime = (vote) => {
        if (!vote) return;

        var deadline = new Date(vote.deadlineDate);
        if (!deadline) return;

        // var deadline = new Date(vote.deadlineDate);
        var now = new Date();
        var diff = now - deadline;
        var deadlineLine = "";
        
        if (diff > 60000)
        {
            deadlineLine = "If this lasts then try refresh your browser " + (diff / 1000) + "s";
        }
        else if (diff > 10000)
        {
            deadlineLine = "Past due, should be ready soon " + (diff / 1000) + "s";
        }
        else if (diff > 0)
        {
            deadlineLine = "Result pending";
        }
        else if (diff > -1000)
        {
            deadlineLine = "nearly there Mr Miyagee!!";
        } 
        else if (diff > -60000)
        {
            deadlineLine = parseInt(Math.ceil(Math.abs(diff) / (1000))) + " seconds";
        }
        else if (diff > -3600000)
        {
            deadlineLine = parseInt(Math.ceil(Math.abs(diff) / (60 * 1000))) + " minutes";
        }
        else if (diff > -86400000)
        {
            deadlineLine = parseInt(Math.ceil(Math.abs(diff) / (60 * 60 * 1000))) + " hours";
        }
        else
        {
            deadlineLine = "more than a day";
        }

        // return (<b>{deadlineLine}</b>)
        return deadlineLine;
    }

    var softDateString = currentVote ? determineSoftDueTime(currentVote) : null;
    const [softDate, setSoftDate] = React.useState(softDateString);

    const handleAddPieceResult = (choice) => {
        gameHub.votePiece(choice);
        // gameHandle.votePiece(choice);
    }

    const votePiece = (principal) => {
        if (gameHandle.votedAddPiece !== true) return null;

        var votePiece = (<AddPiece gameHandle={gameHandle} gameId={gameId} series="classic" colour={principal.colour} addPieceResult={handleAddPieceResult} />);
        return votePiece;
    }

    const renderDueTime = (vote) => {
        if (!vote) return;

        var dl = new Date(vote.deadlineDate);
        var deadlineLine = dl.toLocaleString();

        return (<span className="subheading">{deadlineLine}</span>)
    }

    const renderStats = (gamer) => 
    {
        if (!gamer) return;
        if (!gamer.voter) return;
        var voter = gamer.voter;

        let stats = [];
        if (voter.voters > 0)
        {
            stats.push(<li key={"voters"}><span className="icon bi bi-person" aria-hidden="true" title="Registered Voters"></span>{voter.voters}</li>);
        }
        if (voter.currentVotes > 0)
        {
            stats.push(<li key={"currentVotes"}><span className="icon bi bi-check-square" aria-hidden="true" title="Current Votes"></span>&nbsp;{voter.currentVotes}</li>);
        }
        if (voter.minVotes > 0)
        {
            stats.push(<li key={"minVotes"}><span className="icon bi bi-chevron-bar-down" aria-hidden="true" title="Minimum Votes Needed"></span>&nbsp;{voter.minVotes}</li>);
        }
        // if (voter.maxVotes != 0)
        // {
        //     if (voter.maxVotes == -1) stats.push(<li key={"maxVotes"}><span className="icon bi bi-chevron-bar-up" aria-hidden="true" title="Infinite Voting"></span>&nbsp;&infin;</li>);
        //     else stats.push(<li key={"maxVotes"}><span className="icon bi bi-chevron-bar-up" aria-hidden="true" title="Maximum Votes Required"></span>&nbsp;{voter.maxVotes}</li>);
        // }

        return (
            <ul className="stats">
                {stats}
            </ul>
        )
    }

    const renderDecision = (vote) =>
    {
        if (!vote) return;

        var decision = vote.lastDecision;
        switch (decision)
        {
            case "EXTEND":
                return (<span className="subheading"><i>Vote extended as minimum votes not reached</i></span>)
                break;
            default:
                return;
        }
    }
    
    var principal = game.principal;
    var me = game.me;
    var gamer = game.players[gamerId];

    var actions = [];

    if (!me || !me.isSetup)
    {
        actions = 
            <h3>
                Setup your profile to vote
                <Profile />
            </h3>
        ;
    } 

    // registration
    else if (me.canRegister)
    {
        actions = <>
            <Register gameHandle={gameHandle} gamerId={gamerId} playerName={me.name} homeCountryCode={me.homeCountryCode} />
            <span className="subheading">You can register to vote in this game</span>
            </>;
    }

    // i can vote but i havent voted yet
    else if (me.isVoter && me.canVote && me.currentVote && !me.currentVote.voteDate && me.currentVote.gamerId == gamer.gamerId)
    {
        actions = <b>Vote&nbsp;now&nbsp;{me.name}!</b>;
    }

    // i can vote and i have voted and there are enough votes to complete
    else if (me.isVoter && me.canVote && me.currentVote && me.currentVote.voteDate && gamer.voter && gamer.voter.currentVotes >= gamer.voter.minVotes && me.currentVote.gamerId == gamer.gamerId)
    {
        actions = <span className="subheading">Enough votes to complete, waiting for vote deadline</span>;
    }

    // I can vote and there is a vote and there is a vote date
    else if (me.isVoter && me.canVote && me.currentVote && me.currentVote.voteDate && me.currentVote.gamerId == gamer.gamerId)
    {
        actions = <span className="subheading">Waiting for vote to complete</span>;
    }

    // im hosting it and this is the turn of a voting user
    else if (game.isActive && me.isHost && gamer.voter && gamer.canMove)
    {
        actions = <span className="subheading">Letting the world decide</span>;
    }

    else if (game.isActive && gamer.isVoter && gamer.canMove)
    {
        actions = <span className="subheading">Letting the world decide</span>;
    }

    else if (game.isActive && gamer.voter && !gamer.canMove)
    {
        actions = <span className="subheading">Waiting for someone to make a move</span>;
    }

    else if (game.isActive && me.lastVote)
    {
        actions = <span className="subheading">Waiting for other player to move</span>;
    }

    // just a registered player acting as an observer
    else if (me && game.isActive)
    {
        actions = <span className="subheading">Waiting for something to happen</span>;
    }

    if (actions)
    {
        return (
            <>
            <div className="vertical">
                {renderStats(gamer)}
                {actions}
                {softDate}
                {renderDecision(me.currentVote)}
                {renderDueTime(me.currentVote)}
                {votePiece(principal)}
            </div>
            </>
        );
    }

    // cant vote
    return (
        <div className="vertical">
        {renderStats(gamer)}
        <h3>You cant currently vote</h3>
        </div>
    );

    //{ gamer.voter ? <span className="subheading"><br />{gamer.voter.minVotes} min up to {gamer.voter.maxVotes == -1 ? "unlimited" : gamer.voter.maxVotes + " max"}<br/>Deadline: {renderSoftDueDate(gamer.voter.currentDeadline)}</span> : null }

}

export default Voter;