import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useGameHubCtx } from './GameHub'
import { Game } from './Game';
import './Arena.css'

export const Arena = (props) => {
    const gameHub = useGameHubCtx();

    const indie = props && props.match && props.match.params.pageIndex || 1;
    const [pageIndex, setPageIndex] = React.useState(indie);
    const [list, setList] = React.useState({});
    // const [loading, setLoading] = React.useState(false);
    document.title = 'Mega Battle';
    React.useMemo(function() {
        var cb = function(json) {
            setList(json);
        };
        gameHub.list(pageIndex, 9, cb);
    }, [pageIndex]);


    const onShowPage = (pageIndex) => 
    {
        // fetch(
        //     '/api/gamedata/list/' + pageIndex,
        //     {
        //         credentials: "same-origin",
        //         headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain, */*' },
        //     })
        //     .then(data => data.json())
        //     .then(json => {
        //         //debugger;
        //         if (json) {
        //             console.log(json);
        //             setList(json);
        //         } else {
        //             setList(null);
        //         }
                
        //     });
        var cb = function(json) {
            setList(json);
            // if (json) {
            //     console.log(json);
            //     setList(json);
            // } else {
            //     setList(null);
            // }
        }
        // gameHub.list(pageIndex, cb);
    }

    var inited = false;
    if (!inited)
    {
        onShowPage(pageIndex);
        inited = true;
    }

    // onShowPage(pageIndex);

    // const navigateToGame = (e) => 
    // {
    //     //debugger;
    //     //this.props.history.push(url);
    //     var parser = document.createElement('a');
    //     parser.href = e.currentTarget.attributes['gameurl'].value;
    //     console.log(parser.pathname);
    //     var path = parser.pathname;
    //     //debugger;
    //     if (!path.indexOf('/') === 0 && !path.indexOf('http') === 0) path = '/' + path;
    //     props.history.push(path);
    // }

    const renderItems = (cols) => {
        if (!cols) return null;
        var items = [];
        for (var i = 0; i < cols.length; i++) {
            var col = cols[i];
            var gamerId = col.gameUrl.substring(col.gameUrl.lastIndexOf('/')+1);
            var game = <Game gameId={col.id} gamerId={gamerId} boardOnly={true} />;
            switch (col.result) {
                    case "error":
                    case "setup":

                    items.push((<li key={col.id} gameurl={col.gameUrl} className="card setup">
                        <Link key={col.id} to={col.gameUrl}>
                            {game}
                        </Link>
                    </li>));
                    break;
                    
                case "winner":
                    items.push((<li key={col.id} gameurl={col.gameUrl} className="card winner">
                        <Link key={col.id} to={col.gameUrl}>
                            {game}
                        </Link>
                    </li>));
                    break;
                case "loser":
                    items.push((<li key={col.id} gameurl={col.gameUrl} className="card loser">
                        <Link key={col.id} to={col.gameUrl}>
                            {game}
                        </Link>
                    </li>));
                    break;
                case "draw":
                    items.push((<li key={col.id} gameurl={col.gameUrl} className="card draw">
                        <Link key={col.id} to={col.gameUrl} >
                            {game}
                        </Link>
                    </li>));
                    break;
                case "abandoned":
                    items.push((<li key={col.id} gameurl={col.gameUrl} className="card abandoned">
                        <Link key={col.id} to={col.gameUrl}>
                            {game}
                        </Link>
                    </li>));
                    break;
                case "active":
                    items.push((<li key={col.id} gameurl={col.gameUrl} className={["card", col != null ? (col.myTurn ? "turn" : "noturn") : ""].join(' ')}>
                        <Link key={col.id} to={col.gameUrl}>
                            {game}
                        </Link>
                    </li>));
                    break;
                default:
                    
                    break;
            }

            //var li = (<li key={col.id} gameurl={col.gameUrl} onClick={navigateToGame} className={[col != null ? (col.myTurn ? "turn" : "noturn") : ""].join(' ')}>
            //    <b>{col.opponent}</b>
            //    <span>{col.lastMove}</span>
            //</li>);
            //items.push(li);
        }
        return items;
    }

    const renderPaging = (list) => {
        if (list.count < list.pageSize || !list) {
            return;
        }

        var previous = list.previousPageAvailable ? <li className="pageable" onClick={function () { setPageIndex(list.pageIndex - 1); }}><Link to={'/arena/' + (list.pageIndex - 1)}><span onClick={function () { setPageIndex(list.pageIndex - 1); }}>{list.pageIndex - 1}</span></Link></li> : null;
        var current = <li><span>{list.pageIndex}</span></li>;
        var next = list.nextPageAvailable ? <li className="pageable" onClick={function () { setPageIndex(list.pageIndex + 1); }}><Link to={'/arena/' + (list.pageIndex + 1)}><span onClick={function () { setPageIndex(list.pageIndex + 1); }}>{list.pageIndex + 1}</span></Link></li> : null;

        return (<ul className="paging">
            {previous}
            {current}
            {next}
            
            </ul>);
    }

    if (!list) {
        return (<h3>Loading</h3>);
    }

    if (list && list.items && list.items.length > 0) {
        return (<div>
            <ul className="arena three">
                {renderItems(list.items)}
            </ul>
            {renderPaging(list)}
        </div>);
    }
    else
        return (
            <div className="full">
                <h1>No previous games</h1>
                <p>Go on, start a new game</p>
                {/* <a href="/new">New game</a> */}
                <br />
                <br />
                <br />
            </div>);
    

}

export default History