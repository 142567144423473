import { createContext, useContext, useState } from 'react';

const updateProfile = (playerName, homeCountryCode, cb) => {
    console.log('update profile');

    //debugger;
    var data = JSON.stringify({playerName, homeCountryCode});
    var run = async () => {
        try {
            var url = '/api/profile';
            var options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*'
                },
                body: data,
                credentials: "same-origin",
                method: 'POST'
            };

            fetch(url, options)
                .then(res => {
                    var result = res.json();
                    return result;
                })
                .then(json => {
                    if (!json) {
                        if (cb) cb.error();
                        return;
                    }

                    if ((!json.PlayerName || json.PlayerName.length == 0) && (!json.HomeCountryCode || json.HomeCountryCode.length))
                    {   
                        console.log('profile updated');
                        // notifyUsersUpdated();
                        if (cb) cb.success(json.result);
                        // fetchProfile();
                        window.location.reload();
                        return;
                    }

                    if (cb) cb.error(json);
                })
                ;

        }
        catch (e) {
            console.log('There was an error fetching profile data');
            console.log(e);
        }
    };

    //if (delay) {
    //    //window.setTimeout(function () { run(); if (interval) this.refresh(delay, interval); }, delay);
    //    window.setTimeout(function () { run(); }, delay);
    //}
    //else {
    //    run();
    //}
    run();

};

const userNotifiers = {};

var user = {
    playerName: '',
    homeCountryCode: 'UK',
    updateProfile,
    // userUpdated,
    addNotifier: function(id, newNotifier) { handleAddUserNotifier(id, newNotifier); },
};

/*
==============
Handlers
==============
*/
const handleAddUserNotifier = (id, notifier) => {
    // console.log('Config: adding config notifiers ');
    userNotifiers[id] = notifier;
}

const notifyUsersUpdated = () => {
    console.log('notify userUpdated: ' + user.playerName + ', ' + user.homeCountryCode);

    for (const [key, value] of Object.entries(userNotifiers)) {
        //console.log(key, value);
        value(user);
    }
}

/*==============
Context
==============*/
const UserContext = createContext(user);
export const useUserCtx = () => useContext(UserContext);
export const useUserCtxState = () => {
    const [state] = useContext(UserContext);
    return state;
};
export const UserCtxProvider = ({children,init}) => {
    const usrCtx = useState(user);
    return <UserContext.Provider value={usrCtx}>{children}</UserContext.Provider>

    // const usrCtx = useState(init);
    // return <UserContext.Provider value={user}>{children}</UserContext.Provider>

}
export const UserCtxConsumer = ({children,init}) => {
    const usrCtx = useState(user);
    return <UserContext.Consumer value={usrCtx}>{children}</UserContext.Consumer>

    // const usrCtx = useState(init);
    // return <UserContext.Provider value={user}>{children}</UserContext.Provider>

}


const fetchProfile = () => {
    

    console.log('fetch profile for user');
    //debugger;
    var self = this;
    var run = async () => {
        try {
            var url = '/api/profile';
            var options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*'
                },
                // credentials: "same-origin",
                method: 'GET'
            };

            fetch(url, options)
                .then(res => {
                    //debugger;
                    var result = res.json();
                    return result;
                })
                .then(json => {
                    localStorage.setItem("profile", JSON.stringify(json));

                    if (json)
                    {
                        user.playerName = json.playerName;
                        user.homeCountryCode = json.homeCountryCode;
                        notifyUsersUpdated();
                        // user.addNotifier(userUpdate);
                        // user.userUpdatedObservers.forEach(function(item, index, arr) { item(user) });
                    }
                });

        }
        catch (e) {
            console.log(e);
        }
    };

    run();
    
};

// window.setInterval(function() { fetchProfile(); }, 1000);

fetchProfile(user);