import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormControlLabel, InputLabel, Select, MenuItem, Radio, RadioGroup } from '@mui/material';
// import { Radio, RadioGroup, RadioButton, RadioButtonGroup } from 'material-ui';
// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//     root: {
//       color: "red",
//       margin: theme.spacing(1)
//     }
//   }));

export const Share = (props) => {
    // var state;
    const [open, setOpen] = React.useState(false);

    // debugger;
    const text = props.text;
    const name = props.name;
    const activity = props.activity || 'watch';

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false); 
    };

    const handleShareWhatsapp = (e) => {
        shared(e.target);

        var hashTags = "chess";
        // var urlEncoded = encodeURIComponent(text);
        // var text = encodeURIComponent("I challenge you to a duel!");
        var textPayload = "I challenge you to battle! \r\n" + text + "\n\non squarefight.com";

        // var url = "https://twitter.com/intent/tweet?url=" + urlEncoded + "&via=squarefight_com&text=" + textPayload + "&hashtags=" + hashTags;
        var url = "whatsapp://send?text=" + textPayload;
        window.location = url;
    }

    const handleShareTwitter = (e) => {
        shared(e.target);
        var hashTags = "chess";
        var urlEncoded = encodeURIComponent(text);
        var textEncoded = encodeURIComponent("I challenge you to battle!\n\non squarefight.com");

        var url = "https://twitter.com/intent/tweet?url=" + urlEncoded + "&via=squarefight_com&text=" + textEncoded + "&hashtags=" + hashTags;
        // https://twitter.com/intent/tweet?url=https://localhost:5001/game/19f31f25-6a89-4248-8e71-aef12c7f4fb2/player/ec20f419-ef56-45fb-9ce2-de78da3bc718&text=
        window.location = url;
    }

    const handleShareFacebook = (e) => {
        shared(e.target);
        // var hashTags = "chess";
        // var urlEncoded = encodeURIComponent(text);
        // var textEncoded = encodeURIComponent("I challenge you to battle!");

        var url = "https://www.facebook.com/sharer/sharer.php?u=" + text + "\n\non squarefight.com";
        // https://www.facebook.com/sharer/sharer.php?u=https://localhost:5001/game/19f31f25-6a89-4248-8e71-aef12c7f4fb2/player/ec20f419-ef56-45fb-9ce2-de78da3bc718
        window.location = url;
    }

    const handleShareLinkedIn = (e) => {
        shared(e.target);
        // var hashTags = "chess";
        var urlEncoded = encodeURIComponent(text);
        // var textEncoded = encodeURIComponent("I challenge you to battle!");
        var title = encodeURIComponent("Chess challenge");
        var summary = encodeURIComponent("Challenge your friends, challenge the world");

        var url = "https://www.linkedin.com/shareArticle?mini=true&url=" + urlEncoded + "&title=" + title + "&summary" + summary + "=&source=";
        // https://www.linkedin.com/shareArticle?mini=true&url=https://localhost:5001/game/19f31f25-6a89-4248-8e71-aef12c7f4fb2/player/ec20f419-ef56-45fb-9ce2-de78da3bc718&title=&summary=&source=
        window.location = url;
    }

    const handleShareEmail = (e) => {
        shared(e.target);
        // var hashTags = "chess";
        // var urlEncoded = encodeURIComponent(text);
        // var textEncoded = encodeURIComponent("I challenge you to a duel!");
        var subject = encodeURIComponent("Chess challenge");
        var body = encodeURIComponent("I challenge you to battle!\n" + text + "\n\non squarefight.com");

        var url = "mailto:?&subject=" + subject + "&body=" + body;
        // mailto:?&subject=&body=https://localhost:5001/game/19f31f25-6a89-4248-8e71-aef12c7f4fb2/player/ec20f419-ef56-45fb-9ce2-de78da3bc718
        window.location = url;
    }

    const handleShareClipboard = (e) => {
        copyToClipboard(e, text);
        shared(e.target);
    }

    const renderLinkedInShare = (url) => 
    {
        return (
            <div className="option">
                <button className="share unshared icon bi bi-linkedin" aria-hidden="true" onClick={handleShareLinkedIn}></button>
                LinkedIn
            </div>
        );
    }

    const renderFacebookShare = (url) => 
    {
        return (
            <div className="option">
                <button className="share unshared icon bi bi-facebook" aria-hidden="true" onClick={handleShareFacebook}></button>
                Facebook
            </div>
        );
    }

    const renderEmailShare = (url) => 
    {
        return (
            <div className="option">
                <button className="share unshared icon bi bi-envelope" aria-hidden="true" onClick={handleShareEmail}></button>
                Email
            </div>
        );
    }

    const renderTwitterShare = (url) => 
    {
        return (
            <div className="option">
                <button className="share unshared icon bi bi-twitter" aria-hidden="true" onClick={handleShareTwitter}></button>
                Twitter
            </div>
        );
    }

    const renderWhatsappShare = () =>
    {
        return (
            <div className="option">
                <button className="share unshared icon bi bi-whatsapp" aria-hidden="true" onClick={handleShareWhatsapp}></button>
                WhatsApp
                {/* <a href="whatsapp://send?text=I challenge you to a game of chess on squarefight! {text}" data-action="share/whatsapp/share">Share via Whatsapp</a> */}
            </div>
        );
    }

    const renderClipboardShare = () =>
    {
        return (
            <div className="option">
                <button className="share unshared icon bi bi-clipboard" aria-hidden="true" onClick={handleShareClipboard}></button>
                <span className="code copytext">{text}</span>
            </div>
        );
    }

    const shared = (element) => {
        // element.classList.remove('unshared');

        // element.classList.remove('bi-share');
        // element.classList.add('bi-share-fill');
        
        element.classList.add('shared');
    }

    const getActivityText = () => {
        switch (activity)
        {
            
            case "vote":
                return "Players who know this URL can vote in the game as " + name;
            case "join":
                return "Players who know this URL can join the game as " + name;
            default:
                return "Players who know this URL can watch " + name;
        }
    }

    const copyToClipboard = (e, text) => {
        var self = e.target;

        navigator.clipboard.writeText(text).then(() => {
            /* clipboard successfully set */
            shared(self);

          }, () => {
            /* clipboard write failed */
            console.log('Problem writing to clipboard');
          });
    }

    const activityText = getActivityText();

    return (
        <div>
            <button className="share unshared icon bi bi-share" aria-hidden="true" onClick={handleClickOpen}></button>
            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>Share</DialogTitle>
                <DialogContent>
                    <div>
                        {activityText}
                    </div>
               
                    <div className="vertical">
                        <br/>
                        {renderClipboardShare()}
                        {renderTwitterShare(text)}
                        {renderWhatsappShare()}
                        {renderFacebookShare()}
                        {renderLinkedInShare()}
                        {renderEmailShare()}
                    </div>
                    <br />
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Share;