import './index.css';
import './theme.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { UserCtxProvider } from './components/User';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = ReactDOM.createRoot(document.getElementById('root'));

rootElement.render(
  <BrowserRouter basename={baseUrl}>
    <UserCtxProvider>
    <App />
    </UserCtxProvider>
  </BrowserRouter>
);

registerServiceWorker();
//register();
