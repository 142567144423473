import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import { Link, Glyphicon } from 'react-bootstrap'
import GamePlayer from './GamePlayer';
import Piece from './Piece';
import './Playerbar.css'

export const Playerbar = (props) => {
    const gameHandle = props.gameHandle;
    const game = gameHandle.game;
    const gamerId = props.gamerId;
    var me = game.me;
    const [align, setAlign] = React.useState(props.align);

    const capturedPieces = (pieceRefs) => {
        if (!pieceRefs) return null;

        var captured = [];
        for (var i = 0; i < pieceRefs.length; i++) {
            var piece = game.pieces[pieceRefs[i]];
            captured.push(<Piece key={piece.id} series={piece.series} colour={piece.colour} type={piece.type} gameHandle={gameHandle} />);
        }

        return captured;
    };

    const renderPlayer = (gamer, align) => {
        var turn = gamer.canMove ? 'turn' : 'noturn';
        if (game.status != 'InProgress')
        {
            turn = 'noturn';
        }

        if (me.canVote && me.currentVote && me.currentVote.voteDate && me.colour == gamer.colour)
        {
            turn = 'voted';
        }

        return (
            <div className="battlebox">
                <div className={['playerbar', gamer.colour, turn, align].join(' ')} key={gamer.colour}>
                    <GamePlayer align={align} key={gamer.gamerId} gameHandle={gameHandle} gamerId={gamer.gamerId} />
                    {gamer && gamer.capturedRefs && gamer.capturedRefs.length > 0 ? <div className="captured">{capturedPieces(gamer.capturedRefs)}</div> : <></>}

                </div>
            </div>
        );

    }

    // var game = props.gameHandle;
    var gamer = game.players[gamerId];

    return renderPlayer(gamer, align);
}

export default Playerbar;