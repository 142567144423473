import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import { Link, Glyphicon } from 'react-bootstrap'
// import GamePlayer from './GamePlayer';
import JoinAI from './JoinAI';
import JoinVote from './JoinVote';
import JoinGame from './JoinGame';
import Share from './Share';
import Profile from './Profile'

export const Setup = (props) => {
    var game = props.gameHandle.game;
    var me = game.me;
    var gamer = game.players[props.gamerId];

    if (me.isHost)
    {
        return (
            <>
                <JoinAI gameHandle={props.gameHandle} gamerId={props.gamerId}></JoinAI>
                <JoinVote gameHandle={props.gameHandle} gamerId={props.gamerId}></JoinVote>
                <JoinGame gameHandle={props.gameHandle} gamerId={props.gamerId}></JoinGame>
            </>
        );
    }
    else if (me.canJoin && gamer.canJoin && !me.isSetup)
    {
        return (
            <>
                Setup your profile
                <Profile />
            </>
        );
    } 
    else if (me.canJoin && gamer.canJoin)
    {
        return (
            <>
                <JoinGame gameHandle={props.gameHandle} gamerId={props.gamerId}></JoinGame>
            </>
        );
    }

    return <></>
}

export default Setup;