import React, { Component, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Board from './Board';
import Playerbar from './Playerbar';
import Result from './Result';
import Status from './Status';
import AddPiece from './AddPiece';
// import { useSessionCtx } from './Session';
import { gameLoad, useGameHubCtx } from './GameHub'
import { useGameServerCtx } from './GameServer'


export const Game = (props) => {
    const gameHub = useGameHubCtx();

    const parms = useParams();
    const gameId = props.gameId || parms.gameId;
    const gamerId = props.gamerId || parms.gamerId;
    const boardOnly = props.boardOnly || parms.boardOnly;

    const gameState = gameHub.gameState[gameId];
    const [game, setGame] = React.useState();
    const [pending, setPending] = React.useState(false);
    const [addPiece, setAddPiece] = React.useState(false);
    
    // ================
    // view events
    // ================
    const onFocus = (e) => {
        // console.log('focus');
        ensureConnected();
    }

    const onTap = (e, reset) => {
        console.log('tap ' + reset);
        if (reset) {
            ping(reset);
            localStorage.clear();
            caches.keys().then(function (kl) {
                for (var k in kl) {
                    caches.delete(k).then(function () { console.log('deleted ' + k); });
                }
            });
            window.location.reload(true);
            return;
        }

        // if ((state != null && hubConnection && hubConnection.state !== signalR.HubConnectionState.Connected)) {
        //     console.log('connecting on tap');
        //     connect(null);
        // }
        ensureConnected();
    }

    const onBlur = (e) => {
        console.log('blur');
        //away();
    }

    // ================
    // Game Update
    // ================
    const setPrincipal = (gameUpdate, gamerId) =>
    {
        if (gamerId && gamerId !== gamerId)
        {
            setGamerId(gamerId);
        }
        
        // debugger;
        var active = gameUpdate.players.filter(a => a.canMove);
        if (active.length > 0)
        {
            setGamerId(active[0].gamerId);
        }
    }

    const gameUpdate = (gameUpdate) => {
        //debugger;
        console.log('game update');
        console.log(gameUpdate);

        if (!gameUpdate) {
            console.log('empty game');
            return;
        }

        // if this is the current game
        if (gameUpdate.id.toLowerCase() === gameId.toLowerCase()) {
            setPending(false);
            reset(gameUpdate);

            if (gameUpdate.me && gameUpdate.me.canMove) 
            {
                document.title = '(Move) squarefight';
            } 
            if (gameUpdate.me && gameUpdate.me.canVote) 
            {
                document.title = '(Vote) squarefight';
            }
            else 
            {
                document.title = 'squarefight';
            }

            if (!gamerId) {
                setPrincipal(gameUpdate, gamerId);
            }

            var gState = gameHub.gameState[gameId];
            if (gState.selectedPiece) {
                handleSelected(gState.selectedPiece);
            }

            if (gState.votedTile)
            {
                handleVoting(gState.votedPiece);
            }

        } else {
            console.log('not for me');
        }

        // check the cahce and update as necessary
        // ensure sw is caching the game
        // if ('serviceWorker' in navigator) {

        //     if (window.location.href.indexOf('/join') > -1 && gameUpdate.players) {
        //         //debugger;
        //         var me = gameUpdate.me;
        //         if (!me.canJoin) {
        //             var player = gameUpdate.players.find((o) => { return o.colour === me.colour; }) || gameUpdate.players.find((o) => { return o.colour === gameUpdate.host.colour; });
        //             if (player) {
        //                 window.location.href = player.gamerUrl;
        //             }
        //         }
                
        //     }
        // }
    }

    const handleNotifyUpdate = (gameEvent) => {
        if (gameEvent.id === gameId)
        {
            gameUpdate(gameEvent);

            
        }
    }    

    // ================
    // Moving
    // ================
    const handleSelected = (pieceRef) => {
        //debugger;
        if (pieceRef == null) {
            deselect();
            return;
        }

        var piece = game.pieces[pieceRef];
        // if (game.me.colour !== piece.colour) {
        //     console.log('not your piece');
        //     return;
        // }

        // var gameState = gameHub.gameState[gameId];
        var selectedPiece = game.pieces[gameState.selectedPiece];

        if (selectedPiece) {
            deselect();
        }

        console.log('selected ' + piece.type);

        // setSelectedTile(piece.tile);
        clearHighlight(
            function () { addHighlight(piece.possibleMoves, 'available'); }
        );
        // setSelectedPiece(piece);
        gameState.selectedPiece = piece.id;
    }

    const deselect = (callback) => {
        var selectedPiece = game.pieces[gameState.selectedPiece];

        if (selectedPiece) {
            gameState.selectedPiece = null;
            gameState.selectedTile = null;
            clearHighlight();
            if (callback) callback();
        } 
        // else console.log('Nothing to deselect');

        var votedPiece = game.pieces[gameState.votedPiece];
        if (votedPiece) {
            gameState.votedPiece = null;
            gameState.votedTile = null;
            clearHighlight();
            if (callback) callback();
        } 

        if (addPiece) {
            gameState.addPiece = false;
            setAddPiece(false);            
        }

        console.log('deselected');
    }

    const handleMovePiece = (pieceRef, to) => {
        var piece = game.pieces[pieceRef];
        var toPiece = getPieceOnTile(to);

        if (piece == null) {
            console.log('no piece');
            //refresh();
            gamePull();
            return;
        }

        if (toPiece != null && piece.id === toPiece.id) {
            console.log('deselect its the same piece');
            deselect();
            return;
        }

        var selectedPiece = game.pieces[gameState.selectedPiece];
        // if (!selectedPiece)
        // {

        // }

        if (toPiece && selectedPiece && selectedPiece.colour === toPiece.colour && selectedPiece.id !== toPiece.id) {
            console.log('switching move piece ' + selectedPiece.id + ' to ' + toPiece.id);
            handleSelected(toPiece.id);
            return;
        }

        console.log('move ' + piece.type + ' to ' + to);
        if (!game.me.canMove) {
            console.log('not your go');
            return;
        }

        if (selectedPiece.colour !== game.me.colour)
        {
            console.log('not your colour');
            return;
        }

        if (!selectedPiece.possibleMoves.includes(to)) {
            console.log('invalid move');
            // deselect();
            return;
        }

        // var thePiece = game.pieces[piece];
        if (piece.type.toLowerCase() === "pawn") {
            var tileIndex = toTileIndex(to);
            if ((piece.colour.toLowerCase() === "white" && tileIndex.y === game.board.rows) || (piece.colour.toLowerCase() === "black" && tileIndex.y === 1)) {
                gameState.addPiece = true;
                gameState.selectedTile = to;
                setAddPiece(true);
                return;
            }
        }

        // setMoving(piece);
        
        var cb = function(action) {
            deselect();
        }

        setPending(true);
        gameHandle.gameHub.server.move(gameId, gamerId, piece.id, piece.tile, to, null, cb);
    }

    const handleTagTeam = (pieceRef, tileRef, choice) => {
        if (gameState.addPiece !== true) {
            console.log('shouldnt be able to pick a piece ' + choice);
            console.log(this);
            return;
        }

        var piece = game.pieces[pieceRef];
        if (piece == null || piece.type.toLowerCase() !== "pawn") {
            console.log('the pawn should still be selected');
            //debugger;
        }
        var to = tileRef;

        var cb = function(action) {
            deselect();
        }

        gameHandle.gameHub.server.move(gameId, gamerId, piece.id, piece.tile, to, choice, cb);
    }

    // ================
    // Voting
    // ================
    const devote = (callback) => {
        var votedPiece = gameState.votedPiece;
        if (votedPiece) {
            gameState.votedTile = null;
            gameState.votedPiece = null;

            if (callback) callback();
            clearHighlight();
        } else console.log('Nothing to devote');

        if (gameState.votedAddPiece === true) {
            gameState.voteAddPiece = false;
            gameState.voteAddPieceTo = null;
        }

        console.log('devoted');
    }

    const handleVoting = (pieceRef) => {
        // if (!game.me.canMove) {
        //    return;
        // }

        // debugger;
        if (pieceRef == null) {
            devote();
            return;
        }

        var game = game || gameHandle.game;

        var piece = game.pieces[pieceRef];
        if (game.me.colour !== piece.colour) {
            console.log('cant vote for that piece colour');
            return;
        }

        console.log('voting ' + piece.type + ' ' + piece.tile);

        clearHighlight();
        addHighlight(piece.possibleMoves, 'candidate'); 

        var tiles = game.board.tiles;
        // setVoting(piece.tile);
        // setVotedPiece(piece);
        gameState.votedTile = piece.tile;
        gameState.votedPiece = piece.id;

        setGame(Object.assign({},
            game,
            {
                board: Object.assign({}, game.board, { tiles: tiles })
            }));

        
    }

    const handleVotePiece = (pieceRef, to) => {
        var selectedPiece = game.pieces[pieceRef];

        if (selectedPiece == null) {
            console.log('no piece');
            //refresh();
            gamePull();
            return;
        }

        var toPiece = getPieceOnTile(to);
        if (toPiece != null && selectedPiece.id === toPiece.id) {
            console.log('its the same piece, devote');
            devote();
            return;
        }

        if (toPiece && selectedPiece && selectedPiece.colour === toPiece.colour && selectedPiece.id !== toPiece.id) {
            console.log('switching vote piece ' + selectedPiece.id + ' to ' + toPiece.id);
            handleVoting(toPiece.id);
            return;
        }

        console.log('vote ' + selectedPiece.type + ' to ' + to);
        if (!game.me.canVote) {
            console.log('not your go');
            return;
        }

        if (selectedPiece.colour !== game.me.colour)
        {
            console.log('not your colour');
            return;
        }

        if (!game.me.currentVote)
        {
            console.log('no votes happening');
            return;
        }

        if (canTagTeam(selectedPiece.id, to))
        {
            // setVoteAddPiece(true);
            // setVoteAddPieceTo(to);
            gameState.votedPiece = pieceRef;
            gameState.votedTile = to;
            gameState.addPiece = true;
            setAddPiece(true);
            return;
        }

        var cb = function() {
            devote();
        };

        var currentVote = game.me.currentVote;
        setPending(true);

        gameHub.server.vote(gameId, gamerId, currentVote.voteId, currentVote.moveId, selectedPiece.id, selectedPiece.tile, to, null, cb);
    }

    const handleVoteTagTeam = (pieceRef, tileRef, choice) => {
        if (gameState.addPiece !== true) {
            console.log('shouldnt be able to pick a piece ' + choice);
            console.log(this);
            return;
        }

        var piece = game.pieces[pieceRef];
        if (piece == null || piece.type.toLowerCase() !== "pawn") {
            console.log('the pawn should still be selected');
            //debugger;
            return;
        }

        var currentVote = game.me.currentVote;
        if (!currentVote)
        {
            console.log('no actual vote happening');
            return;
        }
        
        var to = tileRef;
        var cb = function(action) {
            deselect();
        }

        gameHub.server.vote(gameId, gamerId, currentVote.voteId, currentVote.moveId, piece.id, piece.tile, to, choice, cb);
    }
    

    // ================
    // Add Piece Dialog
    // ================
    const handleAddPieceResult = (choice) => {
        if (game.me.currentVote)
        {
            handleVoteTagTeam(gameState.votedPiece, gameState.votedTile, choice);
        }
        else
        {
            handleTagTeam(gameState.selectedPiece, gameState.selectedTile, choice);
        }

        setPending(true);
        setAddPiece(false);
    }

    const renderAddPiece = (series, colour) => {
        if (!addPiece) return null;

        var addPieceComponent = (<AddPiece gameHandle={gameHandle} gameId={gameId} series={series} colour={colour} addPieceResult={handleAddPieceResult} />);
        return addPieceComponent;
    }

    
    // ================
    // Highlighting
    // ================
    const addHighlight = (available, highlight, callback) => {
        if (!available) {
            return;
        }

        var game = game || gameHandle.game;

        var tiles = game.board.tiles;
        tiles.map(col => col.map(tile => {
            if (available.indexOf(tile.reference) > -1) {
                switch (highlight)
                {
                    case "available":
                        tile.available = true;
                        break;
                    case "candidate":
                        tile.candidate = true;
                        break;
                }
            }

            return tile;
        }));

        setGame(Object.assign({},
            game,
            {
                board: Object.assign({}, game.board, { tiles: tiles })
            }));

        if (callback) callback();
    }

    const clearHighlight = (callback) => {
        var game = game || gameHandle.game;

        var tiles = game.board.tiles;

        tiles.map(col => col.map(tile => {
            //debugger;
            tile.available = false;
            tile.selected = false;
            tile.candidate = false;
            tile.voted = false;

            return tile;
        }));

        setGame(Object.assign({},
            game,
            {
                board: Object.assign({}, game.board, { tiles: tiles })
            }));

        if (callback) callback();
    }

    
    // ================
    // Utilities
    // ================
    const canTagTeam = (pieceRef, to) => {
        var piece = game.pieces[pieceRef];
        if (piece.type.toLowerCase() === "pawn") {
            var tileIndex = toTileIndex(to);
            if ((piece.colour.toLowerCase() === "white" && tileIndex.y === game.board.rows) 
                || (piece.colour.toLowerCase() === "black" && tileIndex.y === 1)
                || (piece.colour.toLowerCase() === "red" && tileIndex.x === game.board.cols)
                || (piece.colour.toLowerCase() === "blue" && tileIndex.x === 1)
            ) {
                return true;
            }
        }

        return false;
    }

    const getPieceOnTile = (targetRef) => {
        for (var r = 0; r < game.board.tiles.length; r++) {
            var row = game.board.tiles[r];
            for (var c = 0; c < row.length; c < c++) {
                var cell = row[c];
                if (!cell) continue;

                if (cell.reference === targetRef) {
                    var piece = game.pieces[cell.pieceRef];
                    
                    return piece;
                }
            }
        }

        return null;
    }

    const getTile = (targetRef) => {
        for (var r = 0; r < game.board.tiles.length; r++) {
            var row = game.board.tiles[r];
            for (var c = 0; c < row.length; c < c++) {
                var cell = row[c];
                if (!cell) continue;

                if (cell.reference === targetRef) {
                    return cell;
                }
            }
        }
    }

    const toTileIndex = (tile) => {
        var x = tile.charCodeAt(0) - 97;
        var y = parseInt(tile.substring(1), 10);

        return {
            x: x,
            y: y
        };
    }

    const others = () => {
        var players = game.players;
        var principal = game.principal;
        var otherFiltered = Object.keys(players).reduce(function (filtered, key) {
            // if (p.gamerId != principal.gamerId) filtered[key] = dict[key];
            if (players[key].gamerId != principal.gamerId) filtered[key] = players[key];
            return filtered;
        }, {});
        return otherFiltered;
    } 

    const gameHandle = {
        game: game,
        gameHub: gameHub,
        onTap,
        setPending: setPending,
        getPiece: getPieceOnTile,
        handleMove: handleMovePiece,
        handleSelected,
        handleVoting,
        handleVotePiece: handleVotePiece,
        handleAddPieceResult
    };

    /* === updates ====*/
    const reset = (newGame) => {
        setGame(newGame);
        gameHandle.game = newGame;
        // forceUpdate();
    }

    var initted = false;
    const init = () => {
        if (!initted)
        {
            gameHub.online(gameId, gamerId);

            gameHub.addGameNotifier(gameId, handleNotifyUpdate);

        }

        var rset = function(g) { reset(g); };
        gameHub.load(gameId, gamerId, rset);

        initted = true;
    }

    if (!game && gameId)
    {
        init();
    }

    //debugger;
    if (!game) {
        //debugger;
        return <b>Loading...</b>;
    }
    
    var principal = game.principal;
    var players = game.players;
    var otherPlayers = others();

    if (boardOnly) {
        return (<Board gameHandle={gameHandle} />);
    }

    return (
        <div className="game container">
            <div className="row">
                <Result gameHandle={gameHandle} result={game.status} winner={game.winner} />
            </div>
            <div className="row">
                <div className="col-lg-8 col-xl-6">
                    {
                        Object.keys(otherPlayers).map(function(gamerId, i) {
                            var gamer = players[gamerId];
                            return <Playerbar align="left" key={gamer.gamerId} gameHandle={gameHandle} gamerId={gamer.gamerId} >
                                    {/* <GamePlayer align="left" key={gamer.gamerId} gameHandle={gameHandle} gamerId={gamer.gamerId} /> */}
                                </Playerbar>
                        })
                    }
                    {(pending ? <div className="spinner"></div> : null)}
                    <Board gameHandle={gameHandle} />
                    <Playerbar align="right" key={principal.gamerId} gameHandle={gameHandle} gamerId={principal.gamerId}>
                        {/* <GamePlayer key={principal.gamerId} gameHandle={gameHandle} gamerId={principal.gamerId} /> */}
                    </Playerbar>
                </div>
                <div className="col-lg-4 col-xl-6">
                    {renderAddPiece(principal.series, principal.colour)}
                    <Status gameHandle={gameHandle} />
                    
                </div>
            </div>
        </div>
    );
}
