import React from 'react';

import './NavMenu.css';
import { Profile, ProfileContext } from './Profile';
import { useUserCtx } from './User';
import { useConfigCtx, useConfigCtxState } from './Config';
import { Link } from 'react-router-dom';
import Logo from './Logo'



export const NavMenu = (props) => {
  
  

  const navMenuConfigUpdate = (newConfig) => {
    setTheme(newConfig.theme);
    // setHomeCountryCode(user.homeCountryCode);
  }
  const configCtx = useConfigCtx();
  configCtx.addNotifier('navmenu', navMenuConfigUpdate);
  const [theme, setTheme] = React.useState(configCtx.theme);

  const navMenuUserUpdate = (user) => {
    setPlayerName(user.playerName);
    setHomeCountryCode(user.homeCountryCode);
  }
  const [user, setUser] = useUserCtx();
  user.addNotifier('navmenu', navMenuUserUpdate);

  const [open, setOpen] = React.useState(false);
  const [playerName, setPlayerName] = React.useState(user.playerName);
  const [homeCountryCode, setHomeCountryCode] = React.useState(user.homeCountryCode);
  const [menuDisabled, setMenuDisabled] = React.useState(false);
  
  const toggleNavbar = (e) => {
    setOpen(!open);
    $('#sf-navbar-nav').toggleClass('show');
  }

  

  const renderProfile = () => {
    return (
      <div className="profile">
        <b>{playerName}</b>
        <Profile />
      </div>
    )
  }
  // 'navbar-expand-lg', 
  const profile = renderProfile();
  return (
      <>
      <div className="container container-fluid">
        <div className={['navbar', 'navbar-' + theme].join(' ')} id="navbar"> 
            <Logo onLogoClick={toggleNavbar} />
            {profile}
        </div>
        
      </div>
      <div className="collapse" id="sf-navbar-nav">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item"><Link to='/'>Home</Link></li>
            {/* {(playerName ? <Link to='/' onClick={newGame}>New Game</Link> : null)} */}
            {/* {(playerName ? <li className="nav-item"><a onClick={newGame}>New Game</a></li> : null)} */}
            {(playerName ? <li className="nav-item"><Link to='/new'>New Game</Link></li> : null)}
            <li className="nav-item"><Link to='/history'>Games</Link></li>
            <li className="nav-item"><Link to='/settings'>Settings</Link></li>
          </ul>
        </div>
      </>
    );
  }

  export default NavMenu;
