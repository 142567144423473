import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Home } from './components/Home';
//import { Register } from './components/Register';
//import { Login } from './components/Login';
import { NewGame } from './components/NewGame';
import { Game } from './components/Game';
import { Arena } from './components/Arena';
import { History } from './components/History';
import { Settings } from './components/Settings';
// import { Register } from './components/NewAccount';
// import { Custom } from './components/Custom';
import { Test } from './components/Test';
import { Privacy } from './components/Privacy';
import { TnC } from './components/TnC';

const Routing = () =>
    <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/new" element={<NewGame />}></Route>
        {/* <Route exact path="/custom" element={<Custom />}></Route> */}
        <Route exact path="/settings" element={<Settings />}></Route>
        {/* <Route exact path="/register" elements={<Register />}></Route> */}
        <Route path="/history" element={<History />} />
        <Route path="/history/:pageIndex" element={<History />} />
        {/* <Route exact path="/game/:id/join" element={<Game gameId={id} />} /> */}
        <Route exact path="/game/:gameId/gamer/:gamerId" element={<Game />} />
        <Route exact path="/game/:gameId/player/:gamerId" element={<Game />} />
        <Route exact path="/game/:gameId" element={<Game />} />
        <Route exact path="/arena" element={<Arena />} />
        <Route exact path="/arena/:pageIndex" element={<Arena />} />
        {/* <Route exact path="/game/:gameId/gamer/:gamerId" element={(props) => <Game gameId={props.gameId} gamerId={props.gamerId} /> } /> */}
        {/* <Route exact path="/game/:gameId/player/:gamerId" element={(props) => <Game gameId={props.gameId} gamerId={props.gamerId} /> } /> */}
        {/* <Route exact path="/game/:gameId" render={(props) => <Game gameId={props.gameId} /> } /> */}
        <Route exact path="/test" element={<Test />} />
        <Route exact path="/privacy" element={<Privacy />}></Route>
        <Route exact path="/tnc" element={<TnC />}></Route>
    </Routes>

export default Routing;