import { useContext, createContext } from 'react'

/*==============
Variables
==============*/
var defaultTheme = "dark";
var defaultSet = "classic";
var defaultBoard = "classic";

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
    defaultTheme = "light";
}

var config = { 
    theme: defaultTheme, 
    board: defaultBoard, 
    set:defaultSet, 
    addNotifier: function(id, newNotifier) { handleAddNotifier(id, newNotifier); },
    setTheme: function(theme) { handleSetTheme(theme); },
    setBoard: function(board) { handleSetBoard(board); },
    setSet: function(set) { handleSetSet(set); }
};

/*==============
Handlers
==============*/
const configNotifiers = {};
const handleAddNotifier = (id, notifier) => {
    configNotifiers[id] = notifier;
}

const notifyConfigUpdated = () => {
    for (const [key, value] of Object.entries(configNotifiers)) {
        // console.log(key, value);
        value(config);
    }
}


const handleSetTheme = (newTheme) => {
    console.log('Config: setting the theme from ' + config.theme + ' to ' + newTheme);
    config.theme = newTheme;
    
    save();
    document.body.className = "theme " + config.theme;
}

const handleSetBoard = (newBoard) => {
    console.log('Config: setting the board from ' + config.board + ' to ' + newBoard);
    config.board = newBoard;
    
    save();
}

const handleSetSet = (newSet) => {
    console.log('Config: setting the set from ' + config.set + ' to ' + newSet);
    config.set = newSet;
    
    save();
}

/*==============
Storage
==============*/
const sanitiseJsonState = (json) => {
    if (!json) return { theme: defaultTheme, board: defaultBoard, set: defaultSet };// defaultOptions();

    var themes = ['light', 'dark'];
    var boards = ['classic', 'grey'];

    if (!themes.includes(json.theme)) json.theme = 'dark';
    if (!boards.includes(json.board)) json.board = 'classic';

    return json;
}

const storageKey = "config";
var saved = null;
const save = () => {
    var saving = JSON.stringify(config);
    localStorage.setItem(storageKey, saving);
    saved = saving;
    notifyConfigUpdated();
}

const load = () => {
    var persisted = localStorage.getItem(storageKey);
    if (persisted && persisted !== "undefined") {
        saved = JSON.parse(persisted);
        saved = sanitiseJsonState(saved);
        console.log('loaded saved config');
        console.log(saved);
        
        config.theme = saved.theme;
        config.board = saved.board;
        config.set = saved.set;
        notifyConfigUpdated();
    }
    else
    {
        //config doesnt exist yet so create it
        save();
    }
}

/*==============
init aii
==============*/
load();




/*==============
Contexts
==============*/
const ConfigContext = createContext(config); 
export const useConfigCtx = () => useContext(ConfigContext);
export const useConfigCtxState = () => {
    const [state] = useContext(ConfigContext);
    return state;
};

// export const ConfigCtxProvider = ({children,init}) => {
//     const usrCtx = useState(user);
//     return <ConfigContext.Provider value={usrCtx}>{children}</ConfigContext.Provider>
// }
// export const ConfigCtxConsumer = ({children,init}) => {
//     const usrCtx = useState(user);
//     return <ConfigContext.Consumer value={usrCtx}>{children}</ConfigContext.Consumer>
// }

