import React, { Component } from 'react'
import Share from './Share'
import Flag from 'react-world-flags'

export const Player = (props) => {
    var countryCode = props.countryCode;
    var name = props.name;
    var url = props.url;
    var colour = props.colour;

    if (!countryCode && !name)
    {
        return (<div className='player'>
                <Share text={url} name={colour} activity={"join"} />
                <div className="flag"></div>
                <h3>
                    <a href={url}>Share this players URL</a>
                </h3>
                
            </div>);
    }

    return (<div className='player'>
                <Share text={url} name={name} activity={"watch"} />
                <div className="flag"><Flag code={ countryCode } /></div>
                <h3>
                    <a href={url}>{name}</a>
                </h3>
            </div>);

}

export default Player;