import React, { useContext, createContext } from 'react'
const signalR = require("@microsoft/signalr");

/*==============
Variables
==============*/
var connectionStates = { 0: 'connecting', 1: 'connected', 2: 'reconnecting', 4: 'disconnected' };
var commandStates = { 0: 'waiting', 1: 'pending', 2: 'success', 4: 'failure', 5: 'cleaning' };
var presenceStates = { 0: 'offline', 1: 'online', 2: 'away' };

const defaultBusy = false;
var connection;

/*==============
Commands
==============*/
var commandQueue = [];
const postpone = (cb) => 
{
    commandQueue.push(cb);
}
const executeQueue = () =>
{
    if (session.commandQueue.length > 0) {
        while (session.commandQueue.length>0)
        {
            var cb = session.commandQueue.pop();
            if (cb != null) cb();
        }
    }
}

/*==============
Notifiers
==============*/
const sessionNotifiers = {};
const handleAddSessionNotifier = (id, notifier) => {
    sessionNotifiers[id] = notifier;
}

const notifySessionUpdated = () => {
    for (const [key, value] of Object.entries(sessionNotifiers)) {
        // console.log(key, value);
        value(session);
    }
}

const gameNotifiers = {};
const handleAddGameNotifier = (id, notifier) => {
    gameNotifiers[id] = notifier;
}

const notifyGameUpdated = (game) => {
    for (const [key, value] of Object.entries(gameNotifiers)) {
        // console.log(key, value);
        value(game);
    }
}


/*==============
Session
==============*/
export var session = { 
    presence: 'offline',
    commandStatus: 'waiting',
    commandQueue: commandQueue,
    busy: defaultBusy, 
    addSessionNotifier: function(id, newNotifier) { handleAddSessionNotifier(id, newNotifier); },
    addGameNotifier: function(id, newNotifier) { handleAddGameNotifier(id, newNotifier); },
    // setBusy: function(busy) { handleSetBusy(busy); },
    // connection: connection,
    isConnecting: false,
    connectionStatus: signalR.HubConnectionState.Disconnected,
    connected: false,
    vote: function(gameId, gamerId, voteId, moveId, pieceId, from, to, choice, callback) { vote(gameId, gamerId, voteId, moveId, pieceId, from, to, choice, callback); },
    move: function(gameId, gamerId, pieceId, from, to, choice, callback) { move(gameId, gamerId, pieceId, from, to, choice, callback); },
    list: function(pageIndex, pageSize, callback) { list(pageIndex, pageSize, callback) },
    online: function(gameId, gamerId) { online(gameId, gamerId) },
    away: function(gameId, gamerId) { away(gameId, gamerId) },
    disconnected: function() { disconnected() },
    refresh: function(gameId, gamerId) { refresh(gameId, gamerId) },
    joinGame: function(gameId, gamerId) { joinGame(gameId, gamerId) },
    joinAI: function(gameId, gamerId, playerId) { joinAI(gameId, gamerId, playerId) },
    joinVote: function(gameId, gamerId, minVotes, max, maxVoteChoice, timeLimit) { joinVote(gameId, gamerId, minVotes, max, maxVoteChoice, timeLimit) },
    register: function(gameId, gamerId) { register(gameId, gamerId) },
    
};




// ===========
/// handlers
// ===========
const vote = (gameId, gamerId, voteId, moveId, pieceId, from, to, choice, callback) => 
{
    if (!session.connected) {
        console.log('vote not connected, delegating move');
        postpone(function () { vote(gameId, gamerId, pieceId, from, to, choice, callback); });
        // connect(function () { handleMove(piece, to); });
        return;
    }

    connection
        .invoke('vote', gameId, gamerId, voteId, moveId, pieceId, from, to, choice)
        .then(r => {
            console.log('voted');
            if (callback) callback('voted');
        })
        .catch(err => console.error(err));
}

const move = (gameId, gamerId, pieceId, from, to, choice, callback) =>
{
    // if (hubConnection.state !== signalR.HubConnectionState.Connected) {
    //     console.log('not connected, delegating move');
    //     connect(function () { handleMove(piece, to); });
    //     return;
    // }
    if (!session.connected) {
        console.log('move not connected, delegating move');
        postpone(function () { move(gameId, gamerId, pieceId, from, to, choice, callback); });
        // connect(function () { handleMove(piece, to); });
        return;
    }

    connection
        .invoke('move', gameId, gamerId, pieceId, from, to, choice)
        .then(r => {
            console.log('moved');
            if (callback) callback('moved');
        })
        .catch(err => console.error(err));
}

const list = (pageIndex, pageSize, callback) => 
{
    fetch(
        '/api/gamedata/list/' + pageIndex + '/' + pageSize,
        {
            credentials: "same-origin",
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain, */*' },
        })
        .then(data => data.json())
        .then(json => {
            //debugger;
            if (json) {
                console.log(json);
                if (callback) callback(json);
            } else {
                if (callback) callback(null);
            }
            
        });
}

/* =========================
Server command status
========================*/

// const handleSetBusy = (busy) => {
//     console.log('Session: setting busy from ' + session.busy + ' to ' + busy);
//     session.busy = busy;
// }

/* =========================
Player game presence
========================*/
const online = (gameId, gamerId) => {
    if (!gameId)
    {
        console.log('no game ready');
        return;
    }

    if (!session.connected) {
        console.log('online not connected, delegating move');
        postpone(function () { online(gameId, gamerId); });
        // connect(function () { handleMove(piece, to); });
        return;
    }

    console.log("online");
    // gameHub.online(gameId, gamerId);
    connection.invoke("online", gameId, gamerId);
    // setPresence('online');
}

const away = (gameId, gamerId) => {
    console.log('away');
    connection.invoke("away", gameId, gamerId);
    // gameHub.away(gameId, gamerId);
    // setPresence('away');
    // setConnection(1);
}

// const disconnected = () => {
//     console.log('disconnected');
//     // gameHub.disconnected();
//     setPresence('disconnected');
//     // connection
//     connection.invoke("disconnected");
    
//     // setConnection(0);

//     //$.connection.hub.disconnected(function () {
//     //    setTimeout(function () {
//     //        $.connection.hub.start();
//     //    }, 5000); // Restart connection after 5 seconds.
//     //});
//     // if (window.navigator.online) {
//     //     setTimeout(function () {
//     //         hubConnection.connection.hub.start();
//     //     }, 1000); // Restart connection after 5 seconds.
//     // }
// }

/* =========================
Player joins
========================*/
const addAIPlayer = (gameId, gamerId) =>
{
    // debugger;
    connection
        .invoke('AddAIPlayer', gameId, gamerId, "69696969-6969-6969-6969-696969696969")
        .then(r => {
            console.log('added ai player');
        })
        .catch(err => console.error(err));
}

const addVotePlayer = (gameId, gamerId) =>
{
    // debugger;
    connection
        .invoke('AddVotePlayer', gameId, gamerId, "FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF")
        .then(r => {
            console.log('added vote player');
        })
        .catch(err => console.error(err));
}

const addTeamPlayer = (gameId, gamerId) =>
{
    // debugger;
    connection
        .invoke('AddTeamPlayer', gameId, gamerId, "FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF")
        .then(r => {
            console.log('added vote player');
        })
        .catch(err => console.error(err));
}

// const ensureConnected = () =>
// {
//     if (connection && connection.state !== signalR.HubConnectionState.Connected) {
//         console.log('forcing connect');
//         // connect(null, false);
//     }
// }

const joinGame = (gameId, gamerId) => {
    // console.log(playerName + ' from ' + homeCountryCode + ' joining');
    console.log('joining ' + gamerId);
    connection
        .invoke('join', gameId, gamerId)
        .then(r => {
            //debugger;
            if (r) {
                [r] = 'Required'
            }
            else {
                console.log('joined');
            }
            
        })
        .catch(err => { console.error(err) });
}


const joinAI = (gameId, gamerId, selectedId) => {
    console.log(gameId + ' / ' + gamerId + ' adding ' + selectedId);
    connection
        .invoke('AddAIPlayer', gameId, gamerId, selectedId)
        .then(r => {
            //debugger;
            if (r) {
                [r] = 'Required'
            }
            else {
                console.log('joined');
            }
            
        })
        .catch(err => { console.error(err) });
}

const joinVote = (gameId, gamerId, minVotes, maxVotes, maxVoteChoice, voteTimeAllowed) => 
{
    console.log(gameId + ' / ' + gamerId + ' adding vote player ' + minVotes + '/' + maxVoteChoice + ' ' + maxVotes + ' ' + voteTimeAllowed + ' minutes allowed');
    connection
        .invoke('AddVotePlayer', gameId, gamerId, minVotes, maxVotes, maxVoteChoice, voteTimeAllowed)
        .then(r => {
            //debugger;
            if (r) {
            }
            else {
                console.log('joined');
            }
            
        })
        .catch(err => { console.error(err) });
}

const refresh = (gameId, gamerId) => 
{
    console.log(gameId + ' / ' + gamerId + ' refresh ');
    connection
        .invoke('Refresh', gameId, gamerId, true)
        .then(r => {
            //debugger;
            if (r) {
            }
            else {
                console.log('refreshed');
            }
            
        })
        .catch(err => { console.error(err) });
}


/* =========================
User
========================*/
const register = (gameId, gamerId) => {
    console.log('registering gamer ' + gamerId + ' for game ' + gameId);
    connection
        .invoke('register', gameId, gamerId)
        .then(r => {
            //debugger;
            if (r) {
                [r] = 'Required'
            }
            else {
                console.log('registered');
            }
            
        })
        .catch(err => { console.error(err) });
}

const signUp = (playerName, homeCountryCode) => {
    console.log(playerName + ' from ' + homeCountryCode + ' signing up');
    var run = async () => {
        try {
            var payload = JSON.stringify(new { playerName, homeCountryCode });
            var url = '/api/profile';
            var options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*'
                },
                credentials: "same-origin",
                data: payload
            };

            fetch(url, options)
                .then(res => {
                    //debugger;
                    // var clone = res.clone();
                    // gameSaveToCache(url, res);
                    //return clone.json();
                    //gameUpdate(res);
                    // return clone.json();
                    window.location.reload();
                })
                .then(json => {
                    // gameSave(json);
                    // gameSaveToCache(url, json);
                    // gameUpdate(json);
                });

        }
        catch (e) {
            console.log('There was an error fetching your profile');
            console.log(e);
        }
    };

    run();
}



// ================
// connections
// ================
const setupConnection = (cb) => {
    if (connection)
    {
        // connection.disconnect();
    }

    var newConnection = new signalR.HubConnectionBuilder()
        .withUrl("/gameHub", { transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling, waitForPageLoad: false })
        // .withUrl("/gameHub", { transport: signalR.HttpTransportType.LongPolling, waitForPageLoad: false })
        .configureLogging(signalR.LogLevel.Information)
        .build();

    newConnection.on('game', (game) => {
        // debugger;
        notifyGameUpdated(game);
    });

    newConnection.onclose(async () => {
        //disconnected();
        console.log('hub connection closed');
        setTimeout(connect(), 5000);
        connectionChanged(signalR.HubConnectionState.Disconnected);
    });

    connection = newConnection;
    connect();
}

var oldState = signalR.HubConnectionState.Disconnected;
const connectionChanged = (connectedState) => {
    //console.log(newState);
    
    console.log('SignalR state changed from: ' + oldState
        + ' to: ' + connectedState);

    session.connected = connectedState === signalR.HubConnectionState.Connected;
    session.isConnecting = connectedState === signalR.HubConnectionState.Connecting || signalR.HubConnectionState.Reconnecting;
    oldState = session.connectionStatus;
    session.connectionStatus = connectedState;
    notifySessionUpdated();
    
    // session.
    
}

const networkError = (err) => {
    console.log('error starting connection, network error'); 
    console.log(err); 
    // session.connectionStatus = 'Network'; 
    disconnected();
    notifySessionUpdated();
}


const connect = (callback) => {
    console.log('connect');
    if (!connection)
    {
        console.log('no hub');
        return;
    }

    if (connection.state === signalR.HubConnectionState.Connected || connection.state === signalR.HubConnectionState.Reconnecting) {
        //debugger;
        console.log('already connected');
        console.log('connectionId ' + connection.connectionId)
        if (callback) console.log(callback);
        return;
    }

    // var err = function(e) { 
    //     console.log('error starting connection, network error'); 
    //     session.connectionStatus = 'Network'; 
    //     notifySessionUpdated();
    // };
    connection
        .start({ pingInterval: 5000 })
        .then(() =>
        {
            console.log("Connected session to hub");
            console.log('connectionId ' + connection.connectionId)

            connectionChanged(signalR.HubConnectionState.Connected);
            executeQueue();

            if (callback) {
                callback();
            }
        })
        .catch(networkError);
}

const disconnected = () => {
    console.log('disconnected');
    // setPresence('disconnected');
    session.presence = 'disconnected';
    session.connected = false;
    session.connectionStatus = signalR.HubConnectionState.Disconnected;

    if (window.navigator.online) {
        setTimeout(function () {
            connection.start();
        }, 1000); // Restart connection after 5 seconds.
    }
}

const ensureConnected = () =>
{
    if (connection && connection.state !== signalR.HubConnectionState.Connected) {
        console.log('forcing connect');
        // connect(null, false);
    }
}

setupConnection();

/* ==============
Network Utilities
================*/
var checkConnectedRunning = false;
const checkConnected = () => {
    if (checkConnectedRunning) return;
    checkConnectedRunning = true;

    if (!connection) {
        console.log('no hub');
        return;
    }

    switch (connection.state) {
        case signalR.HubConnectionState.Connected:
            console.debug('connected');

            // executeQueue();

            // if (session.commandQueue.length > 0) {
            //     while (session.commandQueue.length>0)
            //     {
            //         var cb = session.commandQueue.pop();
            //         if (cb != null) cb();
            //     }
            // }
            break;
        default:
            console.log('not connected, trying again');
            // var cb = function() { console.log('restarted by checkConnection') };
            // connection.start();
            // var cb = function() { 
            //     Games.foreach(function(g) {
            //         online(g.id, g.principal.gamerId);
            //     })
                
            // };
            // connect(cb);
            connect();
            //disconnected();
            //debugger;
            //if (window.navigator.online) {
            //    setTimeout(function () {
            //        session.connection.connection.hub.start();
            //    }, 1000); // Restart connection after 5 seconds.
            //}
            // setTimeout(connect(), 500);
            break;
    }

    checkConnectedRunning = false;
}

const newGuid = () => {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

const monitorInterval = 30000;
var monitor;
var instance = newGuid();
const ping = () => {
    if (connection.state === signalR.HubConnectionState.Connected) {
        // debugger;
        connection.invoke("ping", instance, new Date());
    }
}

const startMonitor = () => {
    window.setInterval(function () { 
        checkConnected(); 
        // ping(); 
    }, 
    monitorInterval
    );
}
startMonitor();



/*==============
Contexts
==============*/
const GameServerContext = createContext(session); 
export const useGameServerCtx = () => useContext(GameServerContext);
export const useGameServerCtxState = () => {
    const [state] = useContext(GameServerContext);
    return state;
};

export const ConnectionStatus = (props) => {
    switch (session.connectionStatus) {
        case signalR.HubConnectionState.Disconnected:
            return (<span className="connection down"></span>);
        case signalR.HubConnectionState.Connecting:
        case signalR.HubConnectionState.Reconnecting:
            return (<span className="connection away"></span>);
        case signalR.HubConnectionState.Connected:
            return (<span className="connection online"></span>);
        default:
            return (<span className="connection network"></span>);
    }
}
// export const ConnectionStatus;