import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormControlLabel, InputLabel, Select, MenuItem, Radio, RadioGroup } from '@mui/material';

export const JoinVote = (props) => {
    // var state;
    const [error, setError] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [minVotes, setMinVotes] = React.useState(1);
    const [timeLimit, setTimeLimit] = React.useState(60);


    // const [maxVoteChoice, setmaxVoteChoice] = React.useState("unlimited");
    // const [showMaxVotes, setShowMaxVotes] = React.useState(false);
    // const [maxVotes, setMaxVotes] = React.useState(1);

    // debugger;
    const gameHandle = props.gameHandle;
    const game = gameHandle.game;
    const gameId = game.id;
    const gamerId = props.gamerId;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleJoin = () => {
        // e.preventDefault();
        // debugger;
        // if (maxVoteChoice != "unlimited")
        // {
        //     if (maxVotes < minVotes)
        //     {
        //         setError('Max votes cant be less than min votes');
        //         return;
        //     }
        // }

        if (timeLimit < 1)
        {
            setError('You cant wait less than 1 minute');
            return;
        }

        if (timeLimit > 1440)
        {
            setError('You cant wait more than 1440 minute (1 day)');
            return;
        }

        gameHandle.setPending(true);

        // var max = maxVoteChoice == "max" ? maxVotes : -1;
        var max = -1;
        var maxVoteChoice = "max";
        props.gameHandle.gameHub.joinVote(gameId, gamerId, minVotes, max, maxVoteChoice, timeLimit);

        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false); 
    };

    const handleMinVoteChange = (e) => {
        //debugger;
        if (e.target.value)
        {
            var votes = parseInt(e.target.value);
            setMinVotes(votes);
        }
    }

    // const handleMaxVoteChange = (e) => {
    //     var votes = parseInt(e.target.value);
    //     setMaxVotes(votes);
    // }

    // const handleMaxVoteChoiceChange = (e) => {
    //     var voteChoice = e.target.value;
    //     setmaxVoteChoice(voteChoice);
    //     setShowMaxVotes(voteChoice === "max");
    // }

    const handleVoteTimeChange = (e) => {
        var val = parseInt(e.target.value);
        if (val <= 1440) // 24 hours
        {
            setTimeLimit(val);
        }
    }

    // const classes = useStyles();

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen} color="success">
                Setup Vote
            </Button>
            <h5>setup a vote</h5>
            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>Play by vote</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Players who know the URL can submit a vote for the move they would make
                    </DialogContentText>
                    <form>
                        <FormControl fullWidth onChange={handleMinVoteChange}>
                            {/* <InputLabel id="minVotes">Min Votes</InputLabel> */}
                            <TextField
                                id="minVotes"
                                type="number"
                                defaultValue={minVotes}
                                label="Minimum Votes"
                                onChange={handleMinVoteChange}
                            >
                                
                            </TextField>
                            at least this many votes for the vote to complete
                        </FormControl>
                        <br />
                        <br />

                        
                        {/* <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            aria-label={"max-votes"}
                            name={"max-votes"}
                            defaultValue={maxVoteChoice}
                            onChange={handleMaxVoteChoiceChange}
                            
                        >
                            <FormControlLabel control={<Radio value={"unlimited"} />} label="Unlimited" />
                            <FormControlLabel control={<Radio value={"max"} />} label="Maximum Votes" />
                            <TextField disabled={!showMaxVotes} onChange={handleMaxVoteChange} type="number" defaultValue={maxVotes} label="Maximum Votes" />
                            </RadioGroup>
                            complete vote as soon as this many unique votes is reached
                        </FormControl>
                        <br /> */}
                        <FormControl fullWidth>
                            <TextField
                                id="voteTimeAllowed"
                                type="number"
                                defaultValue={timeLimit}
                                label="Voting Time Allowed"
                                onChange={handleVoteTimeChange}
                                
                            >
                                
                            </TextField>
                            how many minutes to allow for voting to complete
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleJoin}>Join Voter</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default JoinVote;