import React, { Component } from 'react';

export class TnC extends Component {
  // displayName = Home.name

  render() {
      return (
          <div className="home">
             <div className="row">
                <h1>Terms and Conditions for Squarefight LTD</h1>
              </div>
              <div className="row" pt={12} mt={12}>
              <h2>Introduction</h2>
              These Terms and Conditions written on this webpage shall manage your use of our platform Squarefight (the Platform) including the website accessible at Squarefight.com (the Website).<br />
              <br />
              These Terms will be applied fully and affect to your use of this Website. By using this Website, you agree to accept all terms and conditions written herein. You must not use this Website if you disagree with any of these Terms and Conditions.<br />
              <br />
              Minors or people below 18 years old are not allowed to use this Website.<br/>
              <br />
              <h2>Intellectual Property Rights</h2>
              Other than the content you own, under these Terms, Squarefight LTD and/or its licensors own all the intellectual property rights and materials contained in this Website.<br />
              <br/>
              You are granted limited license only for purposes of viewing the material contained on this Website.<br/>
              <br />
              <h2>Restrictions</h2>
              You are specifically restricted from all of the following:<br/>
              <ul>
                  <li>Publishing any Website material in any other media</li>
                  <li>Selling, sublicensing and/or otherwise commercializing any Website material</li>
                  <li>Publicly performing and/or showing any Website material</li>
                  <li>Using this Website in any way that is or may be damaging to this Website</li>
                  <li>Using this Website in any way that impacts user access to this Website</li>
                  <li>Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity</li>
                  <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website</li>
                  <li>Using this Website to engage in any advertising or marketing.</li>
              </ul>
              Certain areas of this Website are restricted from being access by you and Squarefight LTD may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.<br/>
              <br/>
              <h2>Your Content</h2>
              In these Terms and Conditions, “Your Content” shall mean any audio, video, text, images or other material you choose to display on this Website. By displaying Your Content, you grant Squarefight LTD a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.<br />
              <br />
              Your Content must be your own and must not be invading any third-party's rights. Squarefight LTD reserves the right to remove any of Your Content from this Website at any time without notice.<br />
              <br />
              <h2>No warranties</h2>
              This Website is provided “as is,” with all faults, and Squarefight LTD express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.<br />
              The financial models provided on the Platform do not cover all possible scenarios and outcomes and should not be used as the basis for any decisions<br/>
              <br />
              <h2>Limitation of liability</h2>
              In no event shall Squarefight LTD, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website and Platform whether such liability is under contract.  Squarefight LTD, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.<br />
              <br/>
              <h2>Indemnification</h2>
              <b>You hereby indemnify to the fullest extent Squarefight LTD from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.<br/></b>
              <br />
              <h2>Severability</h2>
              If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.<br/>
              <br />
              <h2>Variation of Terms</h2>
              Squarefight LTD is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.<br />
              <br />
              <h2>Assignment</h2>
              The Squarefight LTD is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.<br />
              <br />
              <h2>Entire Agreement</h2>
              These Terms constitute the entire agreement between Squarefight LTD and you in relation to your use of this Website, and supersede all prior agreements and understandings.<br/>
              <br />
              <h2>Governing Law & Jurisdiction</h2>
              These Terms will be governed by and interpreted in accordance with the laws of the United Kingdom, and you submit to the non-exclusive jurisdiction of the national courts located in United Kingdom for the resolution of any disputes.<br/>
              <br />
              <h2>Registered Address</h2>
              Squarefight LTD (14403148) registered in United Kingdom at the address:<br/>
              Squarefight LTD<br/>
              Lytchett House<br/>
              13 Freeland Park<br/>
              Wareham Road<br/>
              Poole<br/>
              Dorset<br/>
              BH16 6FA<br/>
              </div>
          </div>
    );
  }
}
