import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const Register = (props) => {
    // var state;
    const [open, setOpen] = React.useState(false);
    // debugger;
    const gameHandle = props.gameHandle;
    var game = gameHandle.game;
    const gameId = game.id;
    const gamerId = props.gamerId;

    const handleRegister = () => {
        // e.preventDefault();
        // debugger;
        if (gameHandle)
        {
            // gameHandle.register(gameId, gamerId);
            props.gameHandle.gameHub.register(gameId, gamerId);
        } 
        else
        {
            console.log("not plumbed in yet");
        }
    };

    return (
        <div>
            <Button variant="contained" onClick={handleRegister} color="success">
                Register
            </Button>
        </div>
    );
}

export default Register;