import React, { Component } from 'react'
import { Piece } from './Piece'
import { Move } from './Move'

export const Moves = (props) => {
    const gameHandle = props.gameHandle;
    var game = gameHandle.game;


    const renderAbsDateTime = (dt) => {
        if (!dt) return;

        var dl = new Date(dt);
        var localDate = dl.toLocaleString();

        var dtString;


        return dtString;
    }


    const renderSoftDateTime = (dt) => {
        if (!dt) return;

        var dl = new Date(dt);
        var localDate = dl.toLocaleString();

        var now = new Date();
        var diff = Math.abs(dl - now);

        var dtString;

        if (diff < 60000)
        {
            dtString = "< 1 min";
        }
        else if (diff < 3600000)
        {
            dtString = parseInt(Math.ceil(Math.abs(diff) / (60 * 1000))) + " minutes";
        }
        else if (diff < 86400000)
        {
            dtString = parseInt(Math.ceil(Math.abs(diff) / (60 * 60 * 1000))) + " hours";
        }
        else
        {
            dtString = "> 1 day";
        }

        return dtString;
    }

    const renderMoves = (moves) => 
    {
        if (!moves) return;

        var movies = [];
        var keys = Object.keys(moves);
        keys.forEach(function(mv, i)
        {
            var move = moves[mv];
            var piece = game.pieces[move.pieceRef];
            var tile = move.tileRef;

            var pieceNotation = "";
            switch (piece.type) {
                case "king":
                    pieceNotation = "K";
                    break;
                case "queen":
                    pieceNotation = "Q";
                    break;
                case "rook":
                    pieceNotation = "R";
                    break;
                case "bishop":
                    pieceNotation = "B";
                    break;
                case "knight":
                    pieceNotation = "N";
                    break;
                case "pawn":
                    // dont cry for me argentina
                    break;
            }

            // var modifiers = "";
            // move.modifiers.forEach(function(m, i){
            //     switch (m) {
            //         case "capture":
            //             modifiers += "x";
            //             break;
            //     }
            // });
            var statusPiece = move.modifiedPieceRef ? game.pieces[move.modifiedPieceRef] : null;
            var statusPieceRender = statusPiece ? <Piece gameHandle={gameHandle} series={statusPiece.series} colour={statusPiece.colour} type={statusPiece.type} /> : null;
            var capture = move.modifier && move.modifier.includes("capture") ? "x" : "";
            var castle = move.modifier && (move.modifier.includes("castled_short") || move.modifier.includes("castled_long")) ? move.modifier.includes("castled_short") ? " 0-0" : " 0-0-0" : "";
            
            movies.push(<li className="move" key={move.id}>
                <span>{move.fromRef}</span>
                <Piece gameHandle={gameHandle} series={piece.series} colour={piece.colour} type={piece.type} tile={tile} />
                <span>{pieceNotation}{capture}{tile}{castle}</span>
                &nbsp;
                {statusPieceRender}
                {/* <span>{renderAbsDateTime(move.moveDate)}</span> */}
                {/* <span>{renderSoftDateTime(move.moveDate)}</span> */}
            </li>);
        })
        return movies;
    }

    return (
        <>
        <h3>Moves</h3>
        <ol className="moves">
            {renderMoves(game.moves)}
        </ol>
        </>
    );
}

export default Moves;