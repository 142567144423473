import { useContext, createContext } from 'react'

var notify = { 
    supportsNotifications: false,
    sendNotification: function(not) { handleNotification(not); },
    notificationsEnabled: false
};

// const handleNotification = (not) => {
//     console.log('Notification: handling notification');
    
// }

const updateNotificationSubscription = (subs) => {
    if (!notify.supportsNotifications) return;

    console.log('update subscription');
    //debugger;
    var data = subs ? JSON.stringify({ Client: subs.client, Endpoint: subs.endpoint, P256dg: subs.p256dh, Auth: subs.auth }) : null;
    var run = async () => {
        try {
            var url = '/api/notification/subscribe';
            var options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*'
                },
                body: data,
                credentials: "same-origin",
                method: 'POST'
            };

            fetch(url, options)
                .then(res => {
                    //debugger;
                    // var clone = res.clone();
                    // //gameSaveToCache(url, clone);
                    // //return clone.json();
                    // //self.gameUpdate(res);
                    // return res.json();
                    // setOpen(false);
                    // window.location.reload();
                })
                ;

        }
        catch (e) {
            console.log('There was an error fetching game data ' + settingsCtx.gameId);
            console.log(e);
        }
    };

    //if (delay) {
    //    //window.setTimeout(function () { run(); if (interval) refresh(delay, interval); }, delay);
    //    window.setTimeout(function () { run(); }, delay);
    //}
    //else {
    //    run();
    //}
    run();

};

const subscribeToNotifications = () => {
    if (!notify.supportsNotifications) return;

    var notifications = 'off';
    if (Notification && Notification.permission === 'granted') {
        notifications = 'on';
        // var subs = serviceWorker.pushManager.subscribe();

        navigator.serviceWorker.ready.then(function (serviceWorker) {
            // subscribe and return the subscription
            serviceWorker.pushManager.getSubscription()
            .then(function(s) {
                // debugger;
                if (s)
                {
                    updateNotificationSubscription(s);
                }
                else
                {
                    serviceWorker.pushManager
                        .subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: 'BKzMCXzMKUxnE_nNhsqn0TgyxsFh6Ir2PDiEYGUH2qiE6Hp5GNSzx4YiiFEDfpRcPusxFnCElE6UOw_1igfQnFE'
                        })
                        .then(function (subscription) {
                            console.log("User is subscribed.", subscription);
                            updateNotificationSubscription(subscription);
                            return subscription;
                        }, function (error) {
                            console.log(error);
                        });
                }
                
            })
            .catch(function(e) {
                console.log(e);
            });
        });
    }

    // var settings = settings;
    settings.notifications = notifications;
    save(settings);

    setNotifications(notifications);
}

const unsubscribeToNotification = () => {
    if (!notify.supportsNotifications) return;

    var notifications = 'off';

    navigator.serviceWorker.ready.then(function (reg) {
        reg.pushManager.getSubscription()
            .then(function (subscription) {
                if (subscription)
                {
                    subscription.unsubscribe()
                        .then(function (successful) {
                            console.log('unsubscribed ' + successful);
                        })
                        .catch(function (e) {
                            console.log(e);
                        })
                }
            })
    });

    updateNotificationSubscription(null);

    settingsCtx.notifications = notifications;
    save(settingsCtx);
    setNotification(notifications);

}

const checkNotifications = () =>
{
    if (!notify.supportsNotifications) return;

    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        console.warn('Notifications aren\'t supported.');
        setMessage('Notifications aren\'t supported by your web browser');
        return;
    }

    // Check the current Notification permission.
    // If its denied, it's a permanent block until the
    // user changes the permission
    if (Notification && Notification.permission === 'denied') {
        console.warn('The user has blocked notifications.');
        setMessage('Notifications appear to be blocked, try enable them through your web browser for this site.');
        return;
    }

    // Check if push messaging is supported
    if (!('PushManager' in window)) {
        console.warn('Push messaging isn\'t supported.');
        setMessage('Push messaging isn\'t supported by your web browser');
        return;
    }
}

const handleNotification = (notify) => {
    if (!notify.supportsNotifications) return;

    if (notify === 'on' && Notification &&  Notification.permission !== 'granted') {
        enableNotifications();
    }
    else if (notify === 'on' && Notification && Notification.permission === 'granted') {
        subscribeToNotifications();
    }
    else {
        unsubscribeToNotification();
    }
}

const testNotifications = () => {
    if (!notify.supportsNotifications) return;

    console.log('test notification');
    checkNotifications();

    var run = async () => {
        try {
            var url = '/api/notifications/test';
            var options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*'
                },
                // body: data,
                credentials: "same-origin",
                method: 'PUT'
            };

            fetch(url, options)
                .then(res => {
                    console.log('notification initiated');
                })
                ;

        }
        catch (e) {
            console.log('There was an error testing notifications' + settingsCtx.gameId);
            console.log(e);
        }
    };

    run();
}

const isNotificationSupported = () => {
    if (isIOS()) return false;

    return Notification != null;
}

const isPushNotificationSupported = () => {
    if (isIOS()) return false;

    return "serviceWorker" in navigator && "PushManager" in window;
}

const enableNotifications = () => {
    if (!notify.supportsNotifications) return;

    Notification.requestPermission()
        .then(function (status) {
            // debugger;
            // console.log(result);
            // handleNotificationPermission(result);
            if (status == "granted") {
                // getSubscription(reg);
                subscribeToNotifications();
            } else {
                console.log('permission denied');
                setMessage('Notification permission not granted (permission denied)');
            }
        });
}

const disableNotifications = () => {

}

function isIOS() {
    const browserInfo = navigator.userAgent.toLowerCase();
    
    if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
      return true;
    }
    if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
      return true;
    } 
    return false;
  }

const init = () =>
{
    if (isIOS()) return;

    // notify.supportsNotifications = isNotificationSupported();
    // notify.notificationsEnabled = Notification && Notification.permission === 'granted' ? 'on' : 'off'
}

init();

/*==============
Contexts
==============*/
const NotifyContext = createContext(notify); 
export const useNotifyCtx = () => useContext(NotifyContext);
export const useNotifyCtxState = () => {
    const [state] = useContext(NotifyContext);
    return state;
};