import React from 'react';
import { NavMenu } from './NavMenu';
import { useConfigCtx, useConfigCtxState } from './Config';

export const Layout = (props) => {

    // config
    const configCtx = useConfigCtx();
    const [theme, setTheme] = React.useState(configCtx.theme);
    const layoutConfigUpdate = (newConfig) => {
        setTheme(newConfig.theme);
    }
    configCtx.addNotifier('layout', layoutConfigUpdate);

    // session
    // const sessionCtx = useGameServerCtx();
    // const [busy, setBusy] = React.useState(sessionCtx.busy);
    // const layoutSessionUpdate = (newSession) => {
    //     setBusy(newSession.busy);
    // }
    // sessionCtx.addSessionNotifier('layout', layoutSessionUpdate);

    return (

        <div style={{ paddingLeft: 0, paddingRight: 0 }} bsclass={theme} className={["container-fluid", "theme", theme].join(" ")} >
            <div className="row" style={{ marginLeft: 0, marginRight: 0}}>
                {/* <div className="col-sm"> */}
                    <NavMenu />
                {/* </div> */}
            </div>
            <div className="row" style={{ marginLeft: 0, marginRight: 0}}>
                {/* <div className="col-sm"> */}
                    {props.children}
                {/* </div> */}
            </div>
            {/* {renderInstaller()} */}
            <div className="footer">
                <div className="col" l={8}>
                    (we use cookies, nom nom nom)
                </div>
            </div>
            <div pt={4} mt={4} className="footer">
                <div className="col" l={6}>
                    Squarefight LTD
                </div>
                <div className="col" l={6}>
                    <a href="/privacy">Privacy Policy</a>
                </div>
                <div className="col" l={6}>
                    <a href="/tnc">Terms and Conditions</a>
                </div>
            </div>
        </div>
        
    );
}

export default Layout;