import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';

export const Logo = (props) => {
//   displayName = Home.name

    return (
          <div className="logo">
            <IconButton color="inherit" onClick={props.onLogoClick} aria-label="burger">
              <svg width="20" height="20">
                  <rect width="20" height="20" style={{ fill:'#666', stroke: 'rgb(255, 60, 60)', 'strokeWidth': '5', opacity: '1' }} />
              </svg>
            </IconButton>
            <Link to='/history'>
              {/* <svg width="20" height="20">
                <rect width="20" height="20" style={{ fill:'#666', stroke: 'rgb(255, 60, 60)', 'strokeWidth': '5', opacity: '1' }} />
              </svg> */}
              squarefight
            </Link>
          </div>
  );
}
export default Logo;