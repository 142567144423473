import React, { Component } from 'react'
import { Piece } from './Piece';
// import { Glyphicon } from 'react-bootstrap';
import './Result'
import './Result.css'

export const Result = (props) => {
    const gameHandle = props.gameHandle;
    const game = gameHandle.game;

    const renderWinner = (game) => {
        if (!game) return null;

        var winner = game.winner;
        // var noSoWinner = game.players.map(s => s.gamerId !=)

        return (<div>
            <h1>{winner.name} WINNER</h1>
            {/* <h0>{noSoWinner.name} NOT SO WINNER</h0> */}
            {game.reason ? <span className="subheading">{game.reason}</span> : null}
            {renderPieces(winner.captured)}
        </div>);
    };

    const renderLoser = (loser) => {
        if (!loser) return null;

        return (<h1>Think of it as a learning experience</h1>);
    };

    const renderDraw = (reason) => {
        return (
        <div className='result'>
                <h1>ITS A DRAW!</h1>
                {reason ? <h3 className="subheading">({reason})</h3> : null}
        </div>
        );
    };

    const renderErrored = () => {
        return (<h1>Shes dead Jim</h1>);
    };

    const renderAbondoned = () => {
        return (<h1>Game abandoned</h1>);
    };

    const renderPieces = (pieces) => {
        if (!pieces) return null;

        var ren = [];
        //ren.push(<ul>);
        for (var i = 0; i < pieces.length; i++) {
            var piece = pieces[i];
            ren.push(<Piece gameHandle={gameHandle} key={"captured" + piece.id} name={piece.name} series={piece.series} colour={piece.colour} />);
        }

        return ren;
    }

    const renderSetup = (game) => {
        var host = game.host;
        var me = game.me;
        //debugger;

        // if (me.name === host.name && game.isJoinable) {
        //     return (
        //     <div className="inline">
        //         <div>
        //             <h3>Send this url to a friend!</h3>
        //         </div>
        //         <div>
        //             <input id="copyUrl" readOnly={true} value={game.joinGameUrl} onClick={this.highlightUrl} />
        //             {/* <Glyphicon className="copy" glyph='duplicate' onClick={this.copyUrl} /> */}
        //         </div>
        //     </div>);
        // }
        return <></>
    }
    
    const renderResult = (game) => {
        //debugger;
        //if (!result) return;

        if (!game || !game.status) return;
        var result = game.status.toLowerCase();
        var reason = game.reason.toLowerCase();

        switch (result) {
            case "setup":
                return renderSetup(game);
            case "win":
                return renderWinner(game);
            //case "lose":
            //    this.renderLoser();
            //    break;
            case "draw":
                switch (reason)
                {
                    case "drawstalemate":
                        return renderDraw("stalemate");
                    case "drawfiftymove":
                        return renderDraw("50 moves");
                    case "drawthreefold":
                        return renderDraw("threefold");
                    default:
                        return renderDraw();
                }
                
            case "abandoned":
            case "playerAbandoned":
                return renderAbondoned();
            case "error":
                return renderErrored();
            default:
                return null;
        }
    }


    // var game = gameHandle;
    //if (!game.isActive && !game.isJoinable) {
    //    return null;
    //}

    if (game && game.status && game.host && game.status.toLowerCase() === "setup" && game.host.name !== game.me.name) {
        return null;
    }

    return (<div className={['result', game.status.toLowerCase()].join(' ')}>
        {renderResult(game)}
    </div >);

}

export default Result;